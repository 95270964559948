<template>
    <div>
        <input type="hidden" id="gameID" :value="'MegaMillions'">
        <DrawDetail class="hide-tabs"></DrawDetail>
    </div>
  </template>

<script>
    import DrawDetail from '../Draw-Details/draw-detail.vue';

    export default {
        components: {
            DrawDetail,
        },
    }

</script>
<style>
.hide-tabs .wrapper {
    display: none !important;
}
</style>