<template>
    <div>
        <SignInRegisterVue v-if="!userAuth"></SignInRegisterVue>
        <div v-else class="moduleContent cf MyLottoNotifications">
            <span class="alert-success InfoLabel" style="display:none;">Your changes were saved successfully.</span>
            <span class="alert-error ErrorLabel" style="display:none;">ERROR MESSAGE</span>
            <div class="notifUnsub">
                <label for="notifUnsubscribe" style="font-weight:600;">Unsubscribe me from all MyLotto Rewards
                    Promotional Communications</label>
                <input id="notifUnsubAll" type="checkbox" name="notifUnsubscribe" :class="{disabled: disabled}" v-model="unsubAll" data-unsub-all="false" @click="subAll" :disabled="disabled">
            </div>
            <div class="notifList cf">
                <div class="notifHeader">
                    <h3>General Communications</h3>
                    <!-- <p class="notifHeaderName">Push</p> -->
                    <p class="notifHeaderName">Email</p>
                </div>

                <!-- Begin genComm Loop -->
                <div class="notifItem genComm" v-for="genComm in genComms" :key="genComm.preferenceId">
                    <div class="notifItemName cf">
                        {{ genComm.displayName }}
                        <span class="toolTip">
                            <span class="toolTipIcon" :title="genComm.categoryGroup"></span>
                            <span class="toolTipContent" style="display: none">
                                {{ genComm.description }}
                            </span>
                        </span>
                    </div>
                    <div class="notifItemSettings">
                        <div :class="'notifMobileWrapper item' + genComm.preferenceId">
                            <div class="notifLabelMobile">
                                <label class="notifLabel">
                                    <input type="checkbox" class="notifCheckBox" :data-pref-id="genComm.preferenceId" :data-pref-group="genComm.categoryGroup" :data-set-val="genComm.preferenceValue" v-model="genComm.settingValue" @click="submitNotif(genComm.preferenceId, genComm.settingValue, false, $event)" :disabled="disabled">
                                    <span class="notifSlider round" :class="{disabled: disabled}"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Loop -->

                <!-- ----------------------- EXAMPLE ----------------------- -->
                <!-- <div class="notifItem genComm">
                    <div class="notifItemName cf">Ohio Lottery Newsletter<span class="toolTip"><span class="toolTipIcon"
                                title="The Ohio Lottery Newsletter email is published every two weeks and includes exciting new MyLotto Rewards announcements such as new drawings, prizes and promotions. The Newsletter also features new Scratch-Off games available from the Ohio Lottery."></span><span
                                class="toolTipContent" style="display: none;">The Ohio Lottery Newsletter email is
                                published every two weeks and includes exciting new MyLotto Rewards announcements such
                                as new drawings, prizes and promotions. The Newsletter also features new Scratch-Off
                                games available from the Ohio Lottery.</span></span></div>
                    <div class="notifItemSettings">
                        <div class="notifMobileWrapper">
                            <div class="notifLabelMobile">Email</div><label class="notifLabel">Email<input
                                    class="notifCheckBox" type="checkbox" data-pref-id="34" data-pref-group="Email"
                                    data-set-val="false"><span class="notifSlider round"></span></label>
                        </div>
                    </div>
                </div>
                -->

                <div class="notifHeader">
                    <h3>Winning Numbers Notifications</h3>
                    <!-- <p class="notifHeaderName">Push</p> -->
                    <p class="notifHeaderName">Email</p>
                </div>

                <!-- Begin wnNotif Loop -->
                <div class="notifItem wnNotif" v-for="notif in wnNotif" :key="notif.preferenceId" ref="notif">
                    <div class="notifItemName cf">
                        {{ notif.displayName }}
                        <span class="toolTip">
                            <span class="toolTipIcon" :title="notif.description"></span>
                            <span class="toolTipContent" style="display: none;">
                                {{ notif.description }}
                            </span>
                        </span>
                    </div>
                    <div class="notifItemSettings">
                        <div :class="'notifMobileWrapper item' + notif.preferenceId ">
                            <!-- <div class="notifLabelMobile">Email</div> -->
                            <label class="notifLabel">
                                <input class="notifCheckBox" type="checkbox" :class="{disabled: disabled}" :data-pref-id="notif.preferenceId" :data-pref-group="notif.categoryGroup" :data-set-val="notif.preferenceValue" v-model="notif.settingValue" @click="submitNotif(notif.preferenceId, notif.settingValue, false, $event)" :disabled="disabled">
                                    <span class="notifSlider round" :class="{disabled: disabled}"></span>
                                </label>
                        </div>
                    </div>
                </div>
                <!-- End Loop -->

                <!-- ----------------------- EXAMPLE ----------------------- -->
                <!-- <div class="notifItem wnNotif">
                    <div class="notifItemName cf">Pick 3, 4, 5 Midday Winning Numbers<span class="toolTip"><span
                                class="toolTipIcon"
                                title="Get one daily email with winning Midday Pick 3, Pick 4 and Pick 5 numbers."></span><span
                                class="toolTipContent" style="display: none;">Get one daily email with winning Midday
                                Pick 3, Pick 4 and Pick 5 numbers.</span></span></div>
                    <div class="notifItemSettings">
                        <div class="notifMobileWrapper">
                            <div class="notifLabelMobile">Email</div><label class="notifLabel">Email<input
                                    class="notifCheckBox" type="checkbox" data-pref-id="50" data-pref-group="Email"
                                    data-set-val="false"><span class="notifSlider round"></span></label>
                        </div>
                    </div>
                </div> -->

                <div class="notifHeader">
                    <h3>Game Alerts</h3>
                    <!-- <p class="notifHeaderName">Push</p> -->
                    <p class="notifHeaderName">Email</p>
                </div>

                <!-- Begin gameAlerts Loop -->
                <div class="notifItem gameAlerts" v-for="game in gameAlerts" :key="game.preferenceId">
                    <div class="notifItemName cf">
                        {{ game.displayName }}
                    </div>

                    <div class="notifItemSettings" v-if="!game.allowedValues">
                        <div :class="'notifMobileWrapper item' + game.preferenceId">
                            <!-- <div class="notifLabelMobile">Email</div> -->
                            <label class="notifLabel">
                                <!-- Email -->
                                <input class="notifCheckBox" type="checkbox" :class="{disabled: disabled}" :data-pref-id="game.preferenceId" :data-pref-group="game.categoryGroup" :data-set-val="game.preferenceValue" v-model="game.settingValue" @click="submitNotif(game.preferenceId, game.settingValue, false, $event)" :disabled="disabled">
                                <span class="notifSlider round" :class="{disabled: disabled}"></span>
                            </label>
                        </div>
                    </div>

                    <div class="notifItemSettings notifSelectSettings" v-if="game.allowedValues">
                        <label class="notifSelLabel">
                            <span class="notifLblPrefix">Email</span>
                            - Notify me when jackpot exceeds $
                            <select class="notifSelect" :class="{disabled: disabled}" :data-pref-id="game.preferenceId" :data-pref-group="game.categoryGroup" v-model="game.settingValue" @change="submitNotif(game.preferenceId, game.settingValue, true, $event)" :disabled="disabled">
                                <option class="notifSelOpt" value="">No alerts</option>
                                <option class="notifSelOpt" v-for="value in game.allowedValues" :key="value" :value="value">{{ value }}</option>
                            </select>
                        </label>
                    </div>
                </div>
                <!-- End Loop -->

                <!-- ----------------------- EXAMPLE ----------------------- -->
                <!-- <div class="notifItem gameAlerts">
                    <div class="notifItemName cf">Pick 3 High Payout Alert</div>
                    <div class="notifItemSettings">
                        <div class="notifMobileWrapper">
                            <div class="notifLabelMobile">Email</div><label class="notifLabel">Email<input
                                    class="notifCheckBox" type="checkbox" data-pref-id="37" data-pref-group="Email"
                                    data-set-val="false"><span class="notifSlider round"></span></label>
                        </div>
                    </div>
                </div> -->

                <!-- Draw games Alerts -->
                <!-- <div class="notifItem gameAlerts">
                    <div class="notifItemName cf">Mega Millions Jackpot Alerts</div>
                    <div class="notifItemSettings notifSelectSettings">
                        <label class="notifSelLabel">
                            <span class="notifLblPrefix">Email</span>
                            - Notify me when jackpot exceeds $
                            <select class="notifSelect" data-pref-id="4" data-pref-group="Email">
                                <option class="notifSelOpt" value="">No alerts</option>
                                <option class="notifSelOpt" value="100">100 million</option>
                                <option class="notifSelOpt" value="250">250 million</option>
                                <option class="notifSelOpt" value="500">500 million</option>
                                <option class="notifSelOpt" value="750">750 million</option>
                                <option class="notifSelOpt" value="900">900 million</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div class="notifItem gameAlerts">
                    <div class="notifItemName cf">Powerball Jackpot Alerts</div>
                    <div class="notifItemSettings notifSelectSettings">
                        <label class="notifSelLabel">
                            <span class="notifLblPrefix">Email</span>
                            - Notify me when jackpot exceeds $
                            <select
                                class="notifSelect" data-pref-id="19" data-pref-group="Email">
                                <option class="notifSelOpt" value="">No alerts</option>
                                <option class="notifSelOpt" value="100">100 million</option>
                                <option class="notifSelOpt" value="250">250 million</option>
                                <option class="notifSelOpt" value="500">500 million</option>
                                <option class="notifSelOpt" value="750">750 million</option>
                                <option class="notifSelOpt" value="900">900 million</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div class="notifItem gameAlerts">
                    <div class="notifItemName cf">Classic Lotto Jackpot Alerts</div>
                    <div class="notifItemSettings notifSelectSettings"><label class="notifSelLabel"><span
                                class="notifLblPrefix">Email</span> - Notify me when jackpot exceeds $<select
                                class="notifSelect" data-pref-id="28" data-pref-group="Email">
                                <option class="notifSelOpt" value="">No alerts</option>
                                <option class="notifSelOpt" value="3">3 million</option>
                                <option class="notifSelOpt" value="6">6 million</option>
                                <option class="notifSelOpt" value="9">9 million</option>
                                <option class="notifSelOpt" value="12">12 million</option>
                                <option class="notifSelOpt" value="15">15 million</option>
                            </select></label></div>
                </div>
                <div class="notifItem gameAlerts">
                    <div class="notifItemName cf">EZPLAY® Progressive Jackpot Alerts</div>
                    <div class="notifItemSettings">
                        <div class="notifMobileWrapper">
                            <div class="notifLabelMobile">Email</div><label class="notifLabel">Email<input
                                    class="notifCheckBox" type="checkbox" data-pref-id="52" data-pref-group="Email"
                                    data-set-val="false"><span class="notifSlider round"></span></label>
                        </div>
                    </div>
                </div> -->
            </div>
            <p style="display:none;" class="note">*Text message and data rates may apply. Mobile app alerts require
                application installations and login on your mobile device.</p>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import SignInRegisterVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
            SignInRegisterVue
        },
        data: function () {
            return {
                userAuth: false,
                userID: 0,
                disabled: false,
                unsubAll: false,
                token: '',
                genComms: [],
                wnNotif: [],
                gameAlerts: [],
                userPrefSettings: {},
                npc_dataParams: {},
                catTiers: [],
                import: []
            }
        },
        mounted: function () {
            this.userAuth = this.isUserAuthed();
            if (this.userAuth) {

                this.getToken();  
                this.userID = this.getLoggedInUserId();
            }
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                })
                .then(() => {
                    this.getUserPref();
                })
            },
            getUserPref()  {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserCommunicationPreferences/' + this.userID;

                fetch (url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    let pref = json;

                    pref.data.isUnsubscribed === false ? false : this.unsubAll = true;

                    pref.data.isUnsubscribed === true ? this.disabled = true: false;

                    for (let i = 0; i < pref.data.preferences.length; i++) {
                        let d = pref.data.preferences[i],
                            trueBool = (d.settingValue === "true");

                        d.settingValue = trueBool;
                    }
                    // console.log(pref.data.preferences)
                    this.$set(this.userPrefSettings, 'preferences', pref.data.preferences);
                })
                .then(() => {
                    this.groupBytier();
                });
            },
            groupBytier() {
                // let data = this.userPrefSettings.prefCall.preferences,
                let data = this.userPrefSettings.preferences,
                // let data = this.import[0].preferences,
                    genComm = [],
                    wnNotif = [],
                    gameAlerts = [];

                $.each(data, function(i) {
                    let thisCat = data[i].categoryTier;
                    
                    if (thisCat === "General Communications") {
                        genComm.push(data[i]);
                    } else if (thisCat === "Winning Numbers Notifications") {
                        wnNotif.push(data[i]);
                    } else if (thisCat === "Game Alerts") {
                        gameAlerts.push(data[i]);
                    } else {
                        // console.log('==== ERROR ====');
                        // console.log(data[i]);
                    }
                });

                this.genComms = genComm;
                this.wnNotif = wnNotif;
                this.gameAlerts = gameAlerts;

                this.sortByOrder();
            },
            sortByOrder () {
                let genComms = this.genComms,
                    wnNotif = this.wnNotif,
                    gameAlerts = this.gameAlerts;

                let sortedGen = genComms.sort((a,b) => {return a.ItemOrder - b.itemOrder});
                this.genComms = sortedGen;

                let sortedWin = wnNotif.sort((a,b) => {return a.ItemOrder - b.itemOrder});
                this.wnNotif = sortedWin;

                let sortedGames = gameAlerts.sort((a,b) => {return a.ItemOrder - b.itemOrder});
                this.gameAlerts = sortedGames;

                this.sortAllowedValues();
            },
            sortAllowedValues () {
                let games = this.gameAlerts;

                $.each(games, function(i) {
                    if (games[i].allowedValues) {
                        let allowedValues = games[i].allowedValues.split(',');

                        games[i].allowedValues = allowedValues;
                    }
                })

                this.gameAlerts = games;
            },
            submitNotif (d, v, jackpot, e) {
                let protocol = this.getProtocol(),
                    url = protocol + process.env.VUE_APP_APIURL + '/1.0/PAM/UpdateUserCommunicationPreferences';
                
                let value = v,
                    id = d;

                if (jackpot === true) {
                    value = parseInt(value);
                } else {
                    value = !value;
                }

                let preference = [
                    {
                        "userId": this.userID,
                        "preferenceId": id,
                        "preferenceValue": String(value)
                    }
                ];

                // console.log(preference);

                // $('.notifSlider').prop('disabled', true);
                // $('.notifSlider').addClass('disabled');
                this.disabled = true;

                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(preference),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + this.token,
                    }
                })
                .then(response => response.json())
                .then((json) => {
                    let data = json.data;

                    if (data.errorCode === 0) {
                        // OnSuccess
                        let fadeOutCheck = () => {
                            $('.item' + d).removeClass('notifSuccess');
                        }
                        let fadeInCheck = () => {
                            $('.item' + d).addClass('notifSuccess');
                            // $('.notifSlider').prop('disabled', false);
                            // $('.notifSlider').removeClass('disabled');
                            this.disabled = false;
                            setTimeout(fadeOutCheck, 2000);
                        }
                        setTimeout(() => {
                            this.getUserPref();
                            fadeInCheck();
                        }, 2000);
                    } else {
                        // NOTE Uncheck if error (Add Method Below)
                        $('.ErrorLabel').text('Something went wrong, please try again later.').show();
                        var scrollH = $('.ErrorLabel').offset().top;
                        $('html, body').animate({scrollTop: scrollH}, 1000);
                        $('.InfoLabel').hide();
                        $(e.target).prop('checked', false);
                        // $(e.target).prop('disabled', false);
                        // $(e.target).removeClass('disabled');
                        this.disabled = false;
                    }
                })
            },
            subAll() {
                // console.log(this.unsubAll)
                let subSetting = !this.unsubAll;

                subSetting === true ? this.removeAll() : this.setAll();
            },
            removeAll () {
                let protocol = this.getProtocol(),
                    // url = protocol + process.env.VUE_APP_APIURL + '/1.0/PAM/UnsubscribeAll?userId=' + this.userID;
                    url = protocol + process.env.VUE_APP_APIURL + '/1.0/PAM/UnsubscribeAll?userId=' + 1284700;

                // $('.notifSlider').prop('disabled', true);
                // $('.notifSlider').addClass('disabled');

                this.disabled = true;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + this.token,
                    }
                })
                .then(response => response.json())
                .then((json) => {
                    // console.log(json);
                    let d = json;

                    // OnSuccess
                    if (d.data === true) {
                        let fadeOutCheck = () => {
                            $('.item').removeClass('notifSuccess');
                        }
                        let fadeInCheck = () => {
                            $('.item').addClass('notifSuccess');
                            // $('.notifSlider').prop('disabled', false);
                            // $('.notifSlider').removeClass('disabled');
                            // this.disabled = false;
                            setTimeout(() => {
                                this.getUserPref();
                                fadeOutCheck
                            }, 2000);
                        }
                        setTimeout(fadeInCheck, 2000);
                    } else if (d.data === false) {
                        this.unsubAll = false;
                        this.disabled = false;
                    } else if (d.error != null) {
                        // console.log(d.error);
                        this.disabled = false;
                    }
                })
            },
            setAll() {
                let protocol = this.getProtocol(),
                    // url = protocol + process.env.VUE_APP_APIURL + '/1.0/PAM/ResubscribeAll?userId=' + this.userID;
                    url = protocol + process.env.VUE_APP_APIURL + '/1.0/PAM/ResubscribeAll?userId=' + 1284700;

                this.disabled = true;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + this.token,
                    }
                })
                .then(response => response.json())
                .then((json) => {
                    // console.log(json);
                    let d = json;

                    // OnSuccess
                    if (d.data === true) {
                        let fadeOutCheck = () => {
                            $('.item').removeClass('notifSuccess');
                        }
                        let fadeInCheck = () => {
                            $('.item').addClass('notifSuccess');
                            this.disabled = false;
                            setTimeout(() => {
                                this.getUserPref();
                                fadeOutCheck
                            }, 2000);
                        }
                        setTimeout(fadeInCheck, 2000);
                    } else if (d.data === false) {
                        this.unsubAll = false;
                        this.disabled = false;
                    } else if (d.error != null) {
                        // console.log(d.error);
                        this.disabled = false;
                    }
                })
            }
        }
    };
</script>