<template>
    <div class="find-retailer-wrapper">
        <p> There are more than 9,800 Ohio Lottery Retailers throughout the state. 
            Chances are, someone's selling a winning ticket just around the corner from you.
             Find the nearest Lottery location to redeem a winning Ohio Lottery ticket or play a game.
        </p>

        <div class="retail_locations_actions" v-if="showRetailActions">
            
            <p>
            <strong>What do you want to do?</strong> 
            </p>

            <button class="button hollow secondary" type="button" @click="clkRetailAction('REDEEM A WINNING TICKET', false)">Redeem a Winning Ticket</button>
            <button class="button hollow secondary" type="button" @click="clkRetailAction('BUY LOTTERY TICKETS', true)">Find a Lottery Retailer</button>
            <button v-if="sportsGamingEnabled" class="button hollow secondary" type="button" @click="clkRetailAction('FIND A SPORTS GAMING RETAILER', true)">Find a Sports Gaming Retailer</button>
        </div>

        <input type="hidden" id="userLocationLatitude" value="" />
        <input type="hidden" id="userLocationLongitude" value="" />

        <div class="rl_retailerForm" v-if="retailerForm">
            <h5 class="rl-form-title">{{formTitle}}</h5>

            <div>
                <h4>Where are you? *</h4>
                <label for="zipCitySearch" class="visuallyhidden">Zip Code or city name</label> 
                <input v-model="v$.zipCode.$model" class="rl_ZipOrCity" :class="status(v$.zipCode)" placeholder="Zip Code or city name" :disabled="useCurrentLocation" id="zipCitySearch">

                <div v-for="error of v$.zipCode.$errors"
                :key="error.$uid"
                class="zipCodeError">
                    {{ error.$message }}
                </div> 
            </div>

            <div class="rl_userLocation">
                <a @click="getUsersLocation()">{{ userLocationText }}</a>
            </div>

            <div class="rl_winnings_field" v-if="!hideElement">
                <h4>How much winnings do you have to claim? *</h4>
                
                <span> $ </span> <input v-model="v$.claimNumber.$model" :class="status(v$.claimNumber)" inputmode="numeric" placeholder="0" > 
                <div v-for="error of v$.claimNumber.$errors"
                :key="error.$uid"
                class="zipCodeError">
                    {{ error.$message }}
                </div> 
            </div>

            <div class="rl_submit_form">
                <button class="button secondary" @click="clkBack()">Back</button>
                <button class="button secondary" @click.prevent="submitForm" :disabled="v$.$invalid" v-if="!claimHighTier">FIND LOCATIONS</button>
                <a class="button secondary" href="/claiming/cashing-locations/high-tier-cashing-locations" v-if="claimHighTier">FIND LOCATIONS</a>
                
                <!-- <span class="error reqError">Please fill out all required fields.</span> -->
            </div>
        </div>

        <div class="show-results" v-if="showResults">
                <p>Lottery cashing locations near <strong>{{  zipCode }}</strong></p>
                <button class="button hollow secondary" @click="newSearch">New Search</button>
                <ul>
                    <li v-for="(result, objIndex) in pageOfItems" v-bind:key="result.id">
                        <div  :id="'infoContentRes_' + (objIndex+1)">
                            <div class="rl_result_header">
                                <h4><strong>{{ objIndex+1 }}</strong> - <span class="bussinesName">{{ result.businessName }} </span> </h4><p> {{ Math.round(result.distanceInMiles * 10) / 10 }} mi</p>
                            </div>
                            <div class="rl_result_body"> 
                                <div class="rl_result_address">
                                    <p class="addressName">{{ result.address }}</p>
                                    <p>{{ result.city }}, {{ result.state }} {{ result.zip }}</p>
                                    <a :href="'tel:'+result.phone" class="phoneNumber"> {{ formatPhoneNumber(result.phone) }} </a>
                                </div>
                                <div class="rl_result_directions">
                                    <a :href="'https://www.google.ca/maps/place/' + result.address" target="_blank" class="rl_directions_link">
                                        Directions <span class="icon-truck"></span>
                                    </a>
                                </div>
                            </div>
                            <div class="rl_results_footer">
                                <ul>
                                    <li><span class="rl-tag">Games</span></li>
                                    <li><span class="rl-tag" v-if="showCashingUp599">Cashing up to $599</span></li></ul>
                            </div>
                        </div>
                        <div style="display:none">
                            <span class="lng">{{ result.longitude }}</span>
                            <span class="lat">{{ result.latitude }}</span>
                        </div>

                    </li>
                </ul>

                <GmapMap
                :center="center"
                :zoom="11"
                style="width: 68%; height: 602px;float: right;"
                >
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        :label="(index+1).toString()"
                        @click="center=m.position; openInfoWindowTemplate($event)"
                    />

                    <gmap-info-window
                        :options="{
                        maxWidth: 300,
                        pixelOffset: { width: 0, height: -35 }
                        }"
                        :position="infoWindow.position"
                        :opened="infoWindow.open"
                        @closeclick="infoWindow.open=false">
                            <div v-html="infoWindow.template"></div>
                    </gmap-info-window>

                </GmapMap>

                
                <jw-pagination :items="results" @changePage="onChangePage" :pageSize="8"></jw-pagination>
        </div>
    </div>
</template>


<script>
    import JwPagination from 'jw-vue-pagination';
    import { gmapApi } from 'gmap-vue';

    import { useVuelidate } from '@vuelidate/core'
    import { required, minLength, numeric } from '@vuelidate/validators'

    export default {
        components: {
            JwPagination
        },
        setup () {
            return { v$: useVuelidate() }
        },
        data: function () {
            return {
                showRetailActions: true,
                retailerForm: false,
                showResults: false,
                sportsGamingEnabled: false,
                
                zipCode: '',
                claimNumber: '',

                formTitle: '',
                hideElement: false,

                showCashingUp599: false,
                claimHighTier: false,

                useCurrentLocation: false,

                userLocationText: 'Use my current location',
                userLat: '',
                userLong: '',

                results: [],
                pageOfItems: [],
                bussinesName:'',
                addressCity: '',
                county: '',
                markers: [],
                center: {},
                infoWindow: {
                    position: {lat: 0, lng: 0},
                    open: false,
                    template: ''
                },
                errorMessage: '',
                showErr: false,
                checkedTw: 0,
                checkedOffersMp: 0,
                checkedOffersWin: 0,
                hasSportsgaming: false

                
            }
        },
        validations () {
            const validationRules = {
                zipCode: { 
                    minLengthValue: minLength(3),
                    numeric 
                }
            }

            if(!this.useCurrentLocation){
                validationRules.zipCode = { 
                    required
                }
            }

            if (!this.hideElement) {
                // if billing is not the same as shipping, require it
                
                validationRules.claimNumber = {
                    required,
                    numeric
                }
            }

            return validationRules
        },
        watch: {
            claimNumber: function(val) {
                
                let number = parseInt(val)

                // console.log(number)

                if( number >= 25000 ){
                    // console.log()
                    this.claimHighTier = true;
                } else{
                    this.claimHighTier = false;
                }
                
            }
        },
        computed: {
            google() {
                return gmapApi();
            },
        },
        methods: {
            status(validation) {
                return {
                    error: validation.$error
                }
            },
            clkRetailAction(title, hideElement){
                this.showRetailActions = false;
                this.showBuyOption = false;
                this.showSgOption = false;
                this.retailerForm = true;

                this.formTitle = title;

                this.hideElement = hideElement;

                if( title == "FIND A SPORTS GAMING RETAILER"){
                    this.hasSportsgaming = true;
                }


            },
            clkBack(){
                this.retailerForm = false;
                this.showBackBtn = false;
                this.showRetailActions = true;
                this.zipCode = '';
                this.claimNumber = '';
                this.results = [];
                this.v$.$reset();
            },
            formatPhoneNumber(value){
                // console.log(value)
                let text = value;
                if(text != null){
                    text = text.replace(/\D/g,'');
                    if(text.length>3) text=text.replace(/.{3}/,'$&-');
                    if(text.length>7) text=text.replace(/.{7}/,'$&-');
                }
                
                return text;
            },
            async submitForm () {
                const isFormCorrect = await this.v$.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (!isFormCorrect) return

                this.retailerForm = false;
                this.showRetailActions = false;
                this.showResults = true;
                this.getToken();
                
            },
            newSearch(){
                this.zipCode = '';
                this.claimNumber = '';
                this.v$.$reset();
                this.retailerForm = false;
                this.showRetailActions = true;
                this.showResults = false;
            },
            showRetailers(){
                
                const inputFields = document.querySelectorAll(".find-retailer-fields .input-retailer");
                const validInputs = Array.from(inputFields).filter( input => input.value !== "");

                const selectElement = document.querySelector('select');
                let outputSelect = selectElement.options[selectElement.selectedIndex].value;

                // console.log(validInputs)
                // console.log(validInputs.length)
                // console.log(outputSelect.length)

                if( validInputs.length === 0 && outputSelect.length === 0   ){
                    this.showErr = true;
                    this.errorMessage = "You must fill at least one field."
                }else{
                    this.showErr = false;
                    this.getToken();
                }
            },
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();

                this.results = []
                
                var bodyElementByFilters = {
                    "businessName": "",
                    "addressCity": "",
                    "county": "",
                    "zip": "",
                    "latitude": 0,
                    "longitude": 0,
                    "hasWinstation": false,
                    "hasMP": false,
                    "hasMPQK": false,
                    "hasKeno": false,
                    "isSuperRetailer": false,
                    "hasSportsgaming": this.hasSportsgaming
                }

                if(this.zipCode.length){
                    bodyElementByFilters.zip = this.zipCode;
                }

                this.userLat = document.getElementById('userLocationLatitude').value;
                this.userLong = document.getElementById('userLocationLongitude').value;
                if( this.userLat.length && this.userLong.length ){
                    this.zipCode = 'Your location';
                    bodyElementByFilters.latitude = parseInt(this.userLat);
                    bodyElementByFilters.longitude = parseInt(this.userLong);
                }

                if(this.claimNumber.length){
                    // console.log(this.claimNumber)
                    if(this.claimNumber >= 600){
                        bodyElementByFilters.isSuperRetailer = true;
                        this.showCashingUp599 = true;
                    }
                    else{
                        bodyElementByFilters.isSuperRetailer = false;
                        this.showCashingUp599 = false;
                    }
                }

                // console.log(bodyElementByFilters)


                //urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Retailer/GetContentElementByFilters
                const requestOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiToken
                    },
                    body: JSON.stringify(bodyElementByFilters)
                };
                fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Retailer/GetContentElementByFilters", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        // console.log(data)

                        let resultsArray = data.data;
                        const dataArr = resultsArray;
                        const removeZeroLat = dataArr.filter(({latitude}) => !latitude.includes('0.000000000'));

                        // console.log(removeZeroLat)

                        if (this.checkedTw ){
                            const filterKenoCheckbox = removeZeroLat.filter(checkbox => checkbox.hasKeno)
                            this.results = filterKenoCheckbox;
                            // console.log(filterKenoCheckbox)
                        } 
                        if ( this.checkedOffersMp ){
                            const filterOffersMpCheckbox = removeZeroLat.filter(checkbox => checkbox.hasMP)
                            this.results = filterOffersMpCheckbox;
                            // console.log(filterOffersMpCheckbox)
                        }
                        if( this.checkedOffersWin){
                            const filterOffersWinCheckbox = removeZeroLat.filter(checkbox => checkbox.hasWinstation)
                            this.results = filterOffersWinCheckbox;
                            // console.log(filterOffersWinCheckbox)
                        }

                        if( !this.checkedTw && !this.checkedOffersMp && !this.checkedOffersWin ){
                            this.results = removeZeroLat;
                        }

                    });
            },
            getProtocol() {
                return window.location.protocol;
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
                this.infoWindow.open = false

                setTimeout(() => {
                    let lng = document.getElementsByClassName('lng');
                    let lat = document.getElementsByClassName('lat');
                    let arrLng = Array.from( lng )
                    let arrLat = Array.from( lat )

                    //console.log(arrLng, arrLat)

                    this.markers = []

                    if ( arrLat[0] != null ){
                        //console.log('do noth')
                        var centerLat = parseFloat(arrLat[0].innerText)
                        var centerLng = parseFloat(arrLng[0].innerText)
                    }

                    if ( centerLat == null ){
                        this.center = {
                            lat: 40.1,
                            lng: -82.5
                        }
                    }else{
                        this.center = {
                            lat: centerLat,
                            lng: centerLng
                        }
                    }

                    for (var i = 0; i < arrLng.length; i++) {

                        var lngMaker = parseFloat(arrLng[i].innerText);
                        var latMarker = parseFloat(arrLat[i].innerText);

                        this.markers.push({
                            position: {
                                lat: latMarker,
                                lng: lngMaker,
                            }
                        })

                        
                    }


                }, 400);
            },
            openInfoWindowTemplate(e) {
                let infoLat = e.latLng.lat();
                let infoLng = e.latLng.lng();
                
                let markerNumLabel = e.domEvent.target.offsetParent.ariaLabel;

                let getInfoById = document.getElementById('infoContentRes_' + markerNumLabel)

                let infoBussinesName = getInfoById.getElementsByClassName('bussinesName')[0].innerText;
                let infoAddressName = getInfoById.getElementsByClassName('addressName')[0].innerText;
                let infoPhoneNumber = getInfoById.getElementsByClassName('phoneNumber')[0].innerText;

                this.infoWindow.position = { lat: infoLat, lng: infoLng };
                this.infoWindow.template = '<div class="info-windows"><p>' + infoBussinesName + '</p><p>' +  infoAddressName +'</p><p>' + infoPhoneNumber + '</p><p><a href="https://www.google.ca/maps/place/'+ infoAddressName +'" target="_blank">Get direction</a></p></div>';
                this.infoWindow.open = true;
            },
            getUsersLocation(){
                
                this.useCurrentLocation = !this.useCurrentLocation;
                this.userLocationText = this.useCurrentLocation ? "Don't use my current location" : "Use my current location";

                let options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }


                function success(pos) {
                    let crd = pos.coords;
                    // console.log(pos);
                    // console.log("Your current position is:");
                    // console.log("Latitude : " + crd.latitude);
                    // console.log("Longitude : " + crd.longitude);
                    // console.log("More or less : " + crd.accuracy + " meters.");

                    document.getElementById('userLocationLatitude').value = crd.latitude;
                    document.getElementById('userLocationLongitude').value = crd.longitude;
                }

                
                function error(err) {
                    console.warn("ERROR : " + err.code + " : Message : " + err.message);
                }

                navigator.geolocation.getCurrentPosition(success, error, options);

            }
            
        }
    };
</script>

<style lang="scss" scoped>
.find-retailer-wrapper{

    .error {
        border-color: red;
        background: #FDD;
        font-size: inherit;
        font-weight: normal;
    }

    .zipCodeError{
        display: inline;
        color: red;
        margin-left: 5px;
    }
}

.rl_submit_form{
    display: flex;

    button{
        display: inline-block;
        margin-right: 10px;
    }
}

.rl-form-title{
    color: #be0f34;
    font-family: "Roboto", Roboto, sans-serif;
    font-weight: 700;
}

.rl_ZipOrCity{
    width: 200px;
}

.rl_userLocation{
    margin-bottom: 20px;
}

.rl_winnings_field{
    span{
        display: inline-block;
    }

    input{
        display: inline-block;
        width: 200px;
    }
}

.rl_submit_form{
    margin-top: 35px;
}

select{
    line-height: inherit;
}



.show-results{
    overflow: hidden;
    ul{
        margin: 0;
        float: left;
        width: 30%;
    }
    li{
        list-style-type: none;
        padding: 20px 0px;
        border-top: 1px solid #000;

        .bussinesName{
            font-weight: 400;
            font-size: 16px;
        }

        p{
            margin-bottom: 0.5em;
            font-size: 14px;
        }
    }

    .rl_result_header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        h4 {
            margin-bottom: 0;
            font-size: 16px;
            max-width: 65%;
        }

        p {
            margin-bottom: 0;
            font-weight: bold;
            font-size: 16px;
        }
    }

    .rl_result_body {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 0.5em;

        .rl_result_address {
            p {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
            }

            a {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .rl_result_directions a {
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .rl_results_footer{
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            width: 100%;
            float: none;

            li {
                padding: 0px;
                border: none;
                margin-right: 10px;

                .rl-tag {
                    font-size: 12px;
                    display: inline-block;
                    background-color: #e6e6e6;
                    padding: 0px 5px;
                    line-height: 1.75em;
                    border-radius: 4px;
                }
            }
        }
    }

    .pagination{
        float: left;
        width: 100%;
        overflow: hidden;
    }
}
.map{
    height: 425px;
}
.warning{
    width: 100%;
    float: left;
    overflow: hidden;
    background-color: red;
    color: white;
    padding: 3px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
}

</style>