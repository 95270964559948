<template>
  <section class="achievements-details">

    <div class="grid-x align-bottom">
        <div class="cell">
          <div v-for="detail in detailData" :key="detail.id">
          <div class="grid-x details-top">
              <h1 class="cell small-6">{{detail.name}}</h1>  <a class="cell small-6 details-back" @click="backToList">Back to Achievements</a>
              </div>
            <div class="media-object" >         
           
            <div class="media-object-section detail-img-description">
              <img class="thumbnail" :src="detail.imageURL" @error="imageLoadOnError($event, 168, 168)">
            </div>
            <div class="media-object-section detail-description">
              <p v-if="isUserLogged"> You have earned this achievement <br>
                <!-- <strong>{{ detail.currentRepetition }} of {{ detail.repeatLimit === 0 ? 'Unlimited' : detail.repeatLimit }}</strong> -->
                <strong v-if="detail.repeatLimit === 0">{{ detail.currentRepetition }} Times</strong>
                <strong v-else>{{ detail.currentRepetition }} of {{ detail.repeatLimit }} Times</strong>
              </p>
              <div class="item" v-for="progressItem in detail.progressItems" v-if="isUserLogged">
                <div class="progress-bar-wrapper">
                  <progress v-if="!progressItem.isCompleted"  :max="progressItem.maxValue" :value="progressItem.currentValue"></progress>
                  <progress v-else class="completed" max="10" value="10"></progress>
                  <div class="progress-inner-text" v-if="progressItem.isCompleted"> Complete </div>
                  <div class="progress-inner-text" v-if="progressItem.isCumulative && !progressItem.isCompleted"> ${{progressItem.currentValue}} of ${{ progressItem.maxValue }} </div>
                  <div class="progress-inner-text" v-if="!progressItem.isCumulative && !progressItem.isCompleted"> {{progressItem.currentValue}} of {{ progressItem.maxValue }} </div>

                  
                  <p v-if="detail.progressItems.length > 1" class="itemName">{{ progressItem.itemName }}</p>
                </div>
              </div>


              <p class = "achievement-description">
                <span v-html="detail.description"></span>
              </p>

              <p><strong>Limit:</strong> {{ detail.repeatLimit === 0 ? 'Unlimited' : spellItOut(detail.repeatLimit) }}</p>
              <p><strong>Expires:</strong> {{ customFormatter(detail.expires) }}</p>

              <div class="enter-ticket-btn" v-if="isUserLogged">
                <a href="/enter" class="button btn-primary">ENTER TICKET</a>
              </div>
              <div class="enter-ticket-btn" v-if="!isUserLogged">
                <a href="#login-frame-home-intro" class="button btn-primary">Log In</a>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import moment from 'moment';

export default {
    props:['userId', 'achId'],
    components: {
      HollowDotsSpinner
    },
    data: function () {
        return {
          detailData: [],
          isUserLogged: false,
          progress: ''
        }
    },
    mounted: function () {
      this.getToken()
      this.isUserLogged = this.isUserAuthed();
    },
    methods: {
      customFormatter(date) {
        return moment(date).format('MM/DD');
      },
      getToken() {
            // then hit API with token
            this.getAPItoken().then((token) => {
                this.fetchProgress(token);
                this.token = token;
            });
      },
      fetchData(token) {

          let url ='';

          let apiToken = token;

          url = process.env.VUE_APP_ACHIEVEMENT + '/1.0/Achievements/GetAchievement/'+ this.achId;
          

          return fetch(url, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': "Bearer " + apiToken,
                  },
              }
          )
          .then((response) => response.json())
          .then((json) => {
              let data = json.data;
              this.detailData = data;
          })
          .catch((error) => {
              // console.error("There was an error!", error);
              return (this.errorMessage = error);
          });
      },
      fetchProgress(token) {

          let url ='';

          let apiToken = token;
          
          url = process.env.VUE_APP_ACHIEVEMENT + '/1.0/Achievements/GetUserAchievementProgress/' + this.userId +"/" + this.achId;
          

          return fetch(url, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': "Bearer " + apiToken,
                  },
              }
          )
          .then((response) => response.json())
          .then((json) => {
              let data = json.data;
              console.log (data)
              data.length > 0 ? this.detailData = data : this.fetchData(token);
          })
          .catch((error) => {
              // console.error("There was an error!", error);
              return (this.errorMessage = error);
          });
      },
      imageLoadOnError(e, height, width) {
        e.target.src = "https://images.placeholders.dev/?width="+width+"&height="+height
      },
      backToList(){
        this.$emit('toggleView');
      },
      spellItOut(num){
        let theNumber = num;
        let textNumber = '';
        switch (theNumber) {
          case 1:
            textNumber = 'One (1)';
            break;
          case 2:
            textNumber = 'Two (2)';
            break;
          case 3:
            textNumber = 'Three (3)';
            break;
          case 4:
            textNumber = 'Four (4)';
            break;
          case 5:
            textNumber = 'Five (5)';
            break;
          case 6:
            textNumber = 'Six (6)';
            break;
          case 7:
            textNumber = 'Seven (7)';
            break;
          case 8:
            textNumber = 'Eight (8)';
            break;
          case 9:
            textNumber = 'Nine (9)';
            break;
          case 10:
            textNumber = 'Ten (10)';
            break;
          default:
            textNumber = theNumber;
            break;
        }
        return textNumber;
      }
  },
  computed: {
    
  },
};
</script>
