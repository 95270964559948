<template>
  <div>
    <div v-if="!arEnabled && !GameOver">      
      <div class="Landing">      
        <p class="Landing-Eyebrow">WELCOME <br/> TO THE</p>
        <div class="Landing-ImagePhoneMitt">
          <div class="Landing-HeadTextWrap">
            <p class="Landing-Head">PHONE <br/> MITT</p>
            <p class="Landing-SubHead">CHALLENGE</p>
          </div>
          <img class="Landing-Glove" src="/getattachment/f3fb337e-69cf-4c00-8a4d-69013088dbd2/BaseballGloveEND_Success-TEMP.png"/>
        </div>
        <p class="Landing-Body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euism od tincidunt ut laoreet dolore adipiscing elit, sed diam nima gna aliquam erat olutpat.</p>
        <p class="Landing-Disclaimer">For best game experience, please allow access to your phone's motion sensors and camera.</p>
        <button class="Landing-Button" v-if="!arEnabled && !GameOver" @click="enableAR">Play Now</button>
      </div>
      <img class="Landing-Dots" src="/getattachment/3e5091f5-204a-46e5-afd0-cd580eec0d95/Phone-Mtt-Dots-Welcome-TEMP.png"/>
    </div>

    <div v-if="countdown > 0 && arEnabled" class="Countdown">
        <p class="Countdown-Head">YOU'RE <br/>ALL SET!</p>
        <p class="Countdown-SubHead">Hold tight until the third inning and watch the scoreboard for game countdown</p>
        <p class="Countdown-Count">{{ countdown }}</p>
    </div>
    
    <div v-if="arEnabled" class="ar-container">
      <div class="ar-wrapper">
        <a-scene embedded arjs vr-mode-ui="enabled: false">
          <a-entity id="baseball" 
            geometry="primitive: plane; height: 5; width: 5"
            material="src: /getattachment/f5cb76c2-d630-4e2b-8456-f08323ff63dc/Baseball-temp.png; transparent: true"
            scale="1 1 1"></a-entity>
            <a-camera gps-new-camera></a-camera>

        </a-scene>

        <img v-if="showGlove" src="/getattachment/15144c15-a73c-4f5d-8c5d-7b7599c6d413/Baseball-Glove-Temp.png" class="glove" />
      </div>
    </div>

    <div v-if="GameOver">
      <div v-if="GameOverWinner" class="GameOver">
        <p class="GameOver-Head">Great Catch!</p>
        <img class="GameOver-Glove" src="/getattachment/f3fb337e-69cf-4c00-8a4d-69013088dbd2/BaseballGloveEND_Success-TEMP.png"/>
        <p class="GameOver-SubHead">You won {Prize}</p>
        <p class="GameOver-SignInText">Sign in to MyLotto Rewards<sup>&reg;</sup> or create a free account to claim your prize.</p>
        <button class="GameOver-Button" @click="SignIn">CLAIM MY PRIZE</button>
      </div>
      <div v-if="!GameOverWinner" class="GameOver">
        <p class="GameOver-Head">OOPS, <br/>MISSED IT</p>
        <img class="GameOver-Glove" src="/getattachment/4e82481c-2e66-4560-814c-6ea000013860/BaseballGloveEND_Fail-TEMP.png"/>
        <p class="GameOver-SubHead">But you can still be a winner.</p>
        <p class="GameOver-SignInText">Sign in to MyLotto Rewards<sup>&reg;</sup> or create a free account to get your second chance.</p>
        <button class="GameOver-Button" @click="SignIn">AM I A WINNER?</button>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const arEnabled = ref(false);
const countdown = ref(10);
const countdownEnd = ref(20);

const showGlove = ref(false);
const GameOver = ref(false);
const GameOverWinner =  ref(false);

let countdownInterval;
let countdownEndInterval;


const enableAR = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
    arEnabled.value = true;
    startCountdown();
    amIaWinner();
    getLocation();
    document.body.classList.add("PhoneMittBodyWrapper");
  } catch (error) {
    console.error('Camera access denied', error);
  }
};

const getLocation = async () => {
  if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;

      console.log(`User Location: ${lat}, ${lon}`);
      const adjustedLat = lat;
      const adjustedLon = lon;

      const baseball = document.getElementById("baseball");
      baseball.setAttribute("gps-new-entity-place", `latitude: ${adjustedLat}; longitude: ${adjustedLon}`);
  });

  } else {
    const baseball = document.getElementById("baseball");
    baseball.setAttribute("gps-new-entity-place", `latitude: 41.4296874; longitude: -81.4945152`);
  }
}

const SignIn = () => {
  console.log("sign in Prompt");
};

const moveVideoElement = () => {
  setTimeout(() => {
    const video = document.querySelector('video');
    const arWrapper = document.querySelector('.ar-wrapper');
    if (video && arWrapper) {
      arWrapper.appendChild(video);
    }
  }, 1000);
};

const startCountdown = () => {
  moveVideoElement();
  document.body.classList.add("countdown-active");

  countdownInterval = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(countdownInterval);
      showGlove.value = true;

      // Start baseball movement **AFTER countdown finishes**
      const baseball = document.querySelector("#baseball");
      if (baseball) {
        baseball.setAttribute("move-baseball", ""); 
      }

      startEndCountdown();
      document.body.classList.remove("countdown-active");
    }
  }, 1000);
};

AFRAME.registerComponent('move-baseball', {
  schema: {
    duration: { type: 'number', default: 15000 } // 15 seconds
  },
  
  init: function () {
    this.startTime = null;
    this.baseball = this.el;
    
    this.startPos = new THREE.Vector3(0, 2, 0);
    this.endPos = new THREE.Vector3(0, -2, 0); 
    
    this.startSize = { width: 0.1, height: 0.1 };
    this.endSize = { width: 0.4, height: 0.4 };
  },

  tick: function (time, timeDelta) {
    if (!this.startTime) this.startTime = time;
    const elapsed = time - this.startTime;
    const progress = Math.min(elapsed / this.data.duration, 1); 

    const waveFactor = Math.sin(progress * Math.PI * 6) * (1 - progress) * 0.5;
    const newX = this.startPos.x + waveFactor;
    const newY = this.startPos.y - progress; 

    const newWidth = this.startSize.width + (this.endSize.width - this.startSize.width) * progress;
    const newHeight = this.startSize.height + (this.endSize.height - this.startSize.height) * progress;

    this.baseball.setAttribute('position', `${newX} ${newY} 0`);
    this.baseball.setAttribute('geometry', `primitive: plane; width: ${newWidth}; height: ${newHeight}`);
    
    if (progress >= 1) {
      this.el.removeAttribute('move-baseball');
    }
  }
});



const startEndCountdown = () => {
  countdownEndInterval = setInterval(() => {
    countdownEnd.value--;
    if (countdownEnd.value <= 0) {
      clearInterval(countdownEndInterval);
      showGlove.value = false;
      arEnabled.value = false;
      GameOver.value = true;
    }
  }, 1000);
};

const amIaWinner = () => {
  GameOverWinner.value = true;
}


onMounted(() => {
  document.body.classList.remove("countdown-active");
});
</script>

<style>
.PhoneMitt {
  width: 100vw;
  max-width: 100vw;
  padding:0px;
}

.PhoneMittBodyWrapper{
  max-width:100vw !important;
  margin: 0px !important;
}

/* Landing page styles */
.Landing {
  background-color: rgb(91,86,164);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width:100%;
}     

.Landing-Eyebrow{
  color: white;
  text-shadow: 2px 2px 4px black;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  margin: 0px 0px 10px 0px;
}

.Landing-ImagePhoneMitt{
  display: flex;
}

.Landing-HeadTextWrap{
  text-align: center;
  position: relative;
  left: 2%;
}

.Landing-Head{
  background: linear-gradient(270deg, gold, gold, white, gold, gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 5rem;
  margin: 0px;
  line-height: 5rem;
  position: relative;
  z-index: 1;
}

.Landing-SubHead{
  color: white;
  text-shadow: 2px 2px 4px black;
  font-weight: 800;
  font-size: 3rem;
  line-height: 3rem;
  position: relative;
  z-index: 4;
}
.Landing-Glove{
  width: 140px;
  height: 140px;
  position: relative;
  right: 5%;
  z-index: 3;
}

.Landing-Body{
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
}

.Landing-Disclaimer{
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
}

.Landing-Button{
  padding: 10px 20px;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
  background-color: black;
  color: white;
}

.Landing-Dots{
  position: relative;
  width:100vw;
}

/* Countdown Page Styles */
.Countdown {
  background-color: rgb(91,86,164);
  padding: 20px;
  width: 100%;
  height: 92.4vh;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Countdown-Head{
  background: linear-gradient(270deg, gold, gold, white, gold, gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 5rem;
  line-height: 5rem;
  position: relative;
  z-index: 1;
  width:250px;
  margin: 0px auto
}

.Countdown-SubHead{
  color: white;
  text-shadow: 2px 2px 4px black;
  font-weight: 800;
  font-size: 3rem;
  line-height: 3rem;
  position: relative;
  z-index: 4;
  padding: 20px 50px;
}

.Countdown-Count{
  font-size: 3rem;
  padding: 10px;
  font-weight: 600;
}

/* AR Page Styles */
video {
  visibility: hidden;
}

body:not(.countdown-active) video {
  width: 100vw !important;
  height: 92.4vh !important;
  visibility: visible;
  margin: 0px !important;
  z-index: 1 !important;
}

.Countdown-CountEnd{
  font-size: 3rem;
  padding: 10px;
  font-weight: 600;
  position: absolute;
  z-index: 5;
}

.ar-container {
  display: flex;
  flex-direction: column;
  min-height: 92.4vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.ar-wrapper {
  width: 100vw;
  min-height: 92.4vh;
  position: relative;
  display: flex;
}

a-scene {
  width: 100vw !important;
  height: 92.4vh !important;
  z-index: 10;
  position: absolute !important;
  top: 0;
  left: 0;
}

.a-canvas {
  width: 100vw !important;
  height: 92.4vh !important;
  z-index: -2;
}

a-camera {
  width: 100vw !important;
  height: 100vh !important;
  display: block !important;
}


.glove {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px !important;
  height: 200px !important;
  z-index: 12;
}

/* Hide AR.js debug containers */
div#arjsDebugUIContainer {
  display: none !important;
}
.a-loader-title{
  display: none !important;
}

/* GameOver/Winner Page Styles */
.GameOver{
  background-color: rgb(91,86,164);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width:100%;
  height: 92.4vh;
  justify-content: center;
}

.GameOver-Head{
  background: linear-gradient(270deg, gold, gold, white, gold, gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 5rem;
  line-height: 5rem;
  position: relative;
  z-index: 1;
  width:250px;
  margin: 0px auto;
  text-align: center;
}

.GameOver-Glove{
  width: 50%;
}

.GameOver-SubHead{
  color: gold;
  font-weight: 600;
  font-size: 5rem;
  line-height: 5rem;
  text-align: center;
  margin-bottom: 10px;
}

.GameOver-SignInText{
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}

.GameOver-Button{
  padding: 10px 20px;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
  background-color: black;
  color: white;
}
</style>