<template>
    <div class="module">
        <div class="cf">
            <ul class="igLandingList clearfix grid-x grid-margin-x">
                <li class="igLandingItem columns small-6 medium-4 large-3 cell" v-for="game in games"
                    v-bind:key="game.gameID">
                    <a :href="game.nodeAliasPath" class="igLogo">
                        <img alt="" :src="game.mobileAppLogoURL">
                    </a>
                    <a :href="game.nodeAliasPath" class="igName">{{ game.gameName }}</a>
                    <span class="game-id">#{{ game.gameNumber }}</span>
                </li>
            </ul>
        </div>
    </div>
    <!-- <div class="list_scratchoff--byPrice">
        <section class="scratchoff__priceGroup" v-for="(games, price) in pricegroup" v-bind:key="price">
            <h3><a :href="'/Games/ScratchOffs/$'+price+'-Games'">${{ price }} Games</a></h3>
            <ul class="list__games priceGroup__list" v-for="game in games" v-bind:key="game.gameID">
                <li class="priceGroup__item"> -->
    <!-- need to check date and show "NEW" and CSS for games within first 30 days -->
    <!-- <a :href="'/Games/ScratchOffs/$'+price+'-Games/'">{{ gameName }}</a>{{  }}
                </li>
            </ul>
        </section>
    </div> -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: function () {
        return {
            gameValue: document.getElementById('prizeGroupValue').value,
            pricegroup: [],
            games: []
        }
    },
    mounted: function () {
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.checkScratchoffs({ token: token, game: this.gameValue })
                        .then(() => {
                            setTimeout(() => {
                                let games = this.getScratchoffs();
                                this.games = games(this.gameValue);
                                // console.log('------------>>>>>', (this.games.length) % 2);
                                
                                //sort alphabetically on gameName
                                this.games.sort(function(a,b) {
                                    return a.gameName.localeCompare(b.gameName);
                                })

                                
                                let valStyle1;
                                let valStyle2;
                                let valStyle3;

                                this.games.length % 2 > 0 ? valStyle1 = this.games.length % 2 : valStyle1 = 2;
                                this.games.length % 3 > 0 ? valStyle2 = this.games.length % 3 : valStyle2 = 3;
                                this.games.length % 4 > 0 ? valStyle3 = this.games.length % 4 : valStyle3 = 4;

                                let style = document.createElement('style');
                                style.innerHTML = `@media screen and (min-width: 0px) and (max-width: 639px) {
                                    .igLandingItem:nth-last-child(-n+` + valStyle1 +`)::after { border-bottom: none !important }
                                }
                                @media screen and (min-width: 640px) and (max-width: 960px) {
                                    .igLandingItem:nth-last-child(-n+` + valStyle2 +`)::after { border-bottom: none !important }
                                }
                                @media screen and (min-width: 961px){
                                    .igLandingItem:nth-last-child(-n+` + valStyle3 +`)::after { border-bottom: none !important }
                                }
                                `;
                                document.head.appendChild(style);


                            }, 1000);
                        })
                });
        },
        ...mapActions({
            checkScratchoffs: 'getNumbers/getOLCScratchGames'
        }),
        ...mapGetters({
            getScratchoffs: 'getNumbers/getScratchNumbers'
        })
    }
};
</script>
<style lang="scss" scoped>
section.top-bar {
    display: none !important;
}

.igLandingList {
    list-style: none;
    margin: 0;

    li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        margin-bottom: 40px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -4px;
            width: 1px;
            height: 100%;
            border-right: 1px solid #B9BDC3;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -20px;
            right: 0;
            width: calc(100% + 8px);
            height: 1px;
            border-bottom: 1px solid #B9BDC3;
        }

        @media only screen and (max-width: 639px) {
            &:nth-child(2n)::before {
                border-right: none;
            }

        }

        @media only screen and (min-width: 639px) {
            &:nth-child(2n)::before {
                border-right: 1px solid #B9BDC3;
            }

            &:nth-child(3n)::before {
                border-right: none;
            }

        }

        @media only screen and (min-width: 961px) {
            &:nth-child(3n)::before {
                border-right: 1px solid #B9BDC3;
            }

            &:nth-child(4n)::before {
                border-right: none;
            }

        }



        a {
            display: block;
            text-align: center;

            &.igLogo {
                height: 100px;
                margin-bottom: 5px;

                img {
                    width: 100%;
                    height: 100px;
                    object-fit: scale-down;
                }
            }

        }
    }
}
</style>