<template>
    <div>
        <div class="share-story-form" v-if="!successForm">

            <div class="overlay" v-if="showVideoProgressBar">
                <self-building-square-spinner
                    :animation-duration="6000"
                    :size="55"
                    color="#5161ac"
                />
            </div>

            <h3>TELL US YOUR WINNING STORY</h3>

            <div class="grid-x grid-padding-x grid-margin-y">
                <div class="small-12 cell">
                    <label>Story Title *
                        <input type="text" placeholder="Story Title" v-on:keyup="countdownTitle" v-model="v$.storyTitle.$model" :class="status(v$.storyTitle)">
                    </label>

                    <div v-for="error of v$.storyTitle.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div> 

                    <p class='text-right text-small' v-bind:class="{'text-danger': hasError }">{{remainingCountTitle}}  characters remaining of 100.</p>
                    
                </div>

                <div class="small-12 cell">
                    <label>
                        Your winning story * <br>
                        <small><i>In your story, please include the name of the game you played, how much you won and where you purchased the ticket.</i></small>
                        <textarea rows="2" cols="20"  v-on:keyup="countdownTxtArea" v-model="v$.winningStoryTxt.$model" :class="status(v$.winningStoryTxt)" placeholder="Your story" data-maxchar="1000"></textarea>
                    </label>

                    <div v-for="error of v$.winningStoryTxt.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div>

                    <p class='text-right text-small' v-bind:class="{'text-danger': hasError }">{{remainingCountTxtArea}}  characters remaining of 1000.</p>
                    
                </div>

                <div class="small-12 cell">
                    <label>
                        What type of story would you like to tell? * <br>
                        <small><i>Grab your cell phone and take a photo of yourself with your winning ticket or record a short video of yourself telling us about your win.</i></small>
                        <template>
                            <VueFileAgent 
                                ref="vueFileAgent"
                                :multiple="false"
                                :deletable="true"
                                :meta="true"
                                :linkable="false"
                                :accept="'image/*,video/*,video/mp4'"
                                :theme="'list'"
                                :maxSize="'5MB'"
                                :maxFiles="1"
                                :helpText="'Choose images or video files'"
                                :errorText="{
                                    type: 'Invalid file type. Only images or video Allowed',
                                    size: 'Files should not exceed 5mb in size',
                                }"
                                @select="filesSelected($event)"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                v-model="v$.fileRecords.$model" :class="status(v$.fileRecords)">
                            </VueFileAgent>
                        </template>
                    </label>
                </div>
            </div>

            <h3 class="sub-header">TELL US WHO YOU ARE</h3>

            <div class="grid-x grid-padding-x grid-margin-y">
                <div class="medium-6 cell">
                    <label>First Name*
                        <input type="text" placeholder="First Name" v-model="v$.firstName.$model" :class="status(v$.firstName)">
                    </label>

                    <div v-for="error of v$.firstName.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div class="medium-6 cell">
                    <label>Last Name*
                        <input type="text" placeholder="Last Name" v-model="v$.lastName.$model" :class="status(v$.lastName)">
                    </label>

                    <div v-for="error of v$.lastName.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div>
                </div>

                <div class="medium-6 cell">
                    <label>City*
                        <input type="text" placeholder="City" v-model="v$.city.$model" :class="status(v$.city)">
                    </label>

                    <div v-for="error of v$.city.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div>
                </div>

                <div class="medium-6 cell">
                    <label>State*
                        <select v-model="v$.state.$model" name="state" :class="status(v$.state)">
                            <option value="AL">AL</option>
                            <option value="AK">AK</option>
                            <option value="AZ">AZ</option>
                            <option value="AR">AR</option>
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DE">DE</option>
                            <option value="DC">DC</option>
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="HI">HI</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>
                            <option value="IA">IA</option>
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="ME">ME</option>
                            <option value="MD">MD</option>
                            <option value="MA">MA</option>
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NV">NV</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NY">NY</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option selected="selected" value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VT">VT</option>
                            <option value="VA">VA</option>
                            <option value="WA">WA</option>
                            <option value="WV">WV</option>
                            <option value="WI">WI</option>
                            <option value="WY">WY</option>

                        </select>

                    </label>

                    <div v-for="error of v$.state.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div>
                </div>

                <div class="medium-6 cell">
                    <label>Email Address*
                        <input type="text" placeholder="Email Address" v-model="v$.email.$model" :class="status(v$.email)">
                    </label>

                    <div v-for="error of v$.email.$errors"
                    :key="error.$uid"
                    class="custom-error-msg">
                        {{ error.$message }}
                    </div>
                </div>

                <div class="medium-6 cell">
                    <div class="grid-x grid-padding-x grid-margin-y">
                        <div class="medium-12 cell">
                            <label>Date of Birth*</label>
                        </div>

                        <div class="medium-12 cell">
                            <datepicker 
                                    placeholder="Select Date" 
                                    name="dateOfBirth"
                                    :format="customFormatter"
                                    v-model="v$.date_birth.$model"
                                    @selected="dateSelected()"
                                    :input-class="{'is_valid': birth_error}"
                                    :class="status(v$.date_birth)"
                                    required
                                    >
                            </datepicker>

                            <div v-for="error of v$.date_birth.$errors"
                            :key="error.$uid"
                            class="custom-error-msg">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="medium-12 cell">
                            <small><i>You must be at least 18 years old to submit your story.</i></small>
                        </div>
                        
                    </div>
                    
                </div>
            </div>

            <div class="grid-x grid-padding-x">
                <button type="button" class="button primary" @click="submit">Submit my story</button>
            </div>
            
            
        </div>

        <div class="grid-container success-story-msg" v-if="successForm">
            <div class="callout success">
                <h5>Story Submission Success!</h5>
                <p>Thanks for submitting your story. It's currently being reviewed by our team and, as long as it doesn’t violate any codes of conduct, will be posted to our site shortly. Thank you for inspiring others by sharing your big win!</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from "vue-moment-datepicker";
    import moment from 'moment';
    import { SelfBuildingSquareSpinner  } from 'epic-spinners'

    import { useVuelidate } from '@vuelidate/core'
    import { required, helpers, maxLength, email, alpha } from '@vuelidate/validators'

    export default {
        components: {
            SelfBuildingSquareSpinner,
            Datepicker
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations () {
            return {
                storyTitle: { required: helpers.withMessage('This field cannot be empty', required) },
                winningStoryTxt: { 
                    required: helpers.withMessage('This field cannot be empty', required) ,
                    maxLength: maxLength(1000)
                },
                
                fileRecords: { required: helpers.withMessage('This field cannot be empty', required) },
                firstName: {
                    required: helpers.withMessage('This field cannot be empty', required) 
                },
                lastName: {
                    required: helpers.withMessage('This field cannot be empty', required) 
                },
                city: { required: helpers.withMessage('This field cannot be empty', required) },
                state: { required: helpers.withMessage('This field cannot be empty', required) },
                email: {  
                    email: helpers.withMessage('Please provide a valid email address', email),
                    required: helpers.withMessage('This field cannot be empty', required)
                },
                date_birth: { 
                    required: helpers.withMessage('This field cannot be empty', required),
                    validateBirth: helpers.withMessage('You must be at least 18.', this.validateBirth) 
                }
            }
        },
        data: function () {
            return {
                successForm: false,

                apiToken: '',
                date_birth: '',
                birth_error: false,

                showVideoProgressBar: false,
                storyTitle: '',
                winningStoryTxt: '',

                hasError: false,

                maxCountTxtArea: 1000,
                remainingCountTxtArea: 1000,
                hasErrorTxtArea: false,

                maxCountTitle: 100,
                remainingCountTitle: 100,
                hasErrorTitle: false,

                
                firstName: '',
                lastName: '',
                city: '',
                state: 'OH',
                email: '',

                fileRecords: [],
                uploadUrl: '',
                uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
                vimeoUrl: '',
                fileRecordsForUpload: [],

                formData: new FormData()  
            }
        },
        mounted: function () {
            this.getToken()
        },
        methods: {
            status(validation) {
                return {
                    error: validation.$error
                }
            },
            countdownTitle: function(){
                this.remainingCountTitle = this.maxCountTitle - this.storyTitle.length;
                this.hasErrorTitle = this.remainingCountTitle < 0;
            },
            countdownTxtArea: function() {
                this.remainingCountTxtArea = this.maxCountTxtArea - this.winningStoryTxt.length;
                this.hasErrorTxtArea = this.remainingCountTxtArea < 0;
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.apiToken = token;
                });

            },
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },    
            dateSelected () {
                this.$nextTick(function(){
                    function getAge(birth, target) {
                        let months = target.diff(birth, 'months', true)
                        let birthSpan = {year: Math.floor(months/12), month: Math.floor(months)%12, day: Math.round((months%1)*target.daysInMonth(),0)}

                        if (birthSpan.year < 1 && birthSpan.month < 1) {
                            return 10
                        } else if (birthSpan.year < 1) {
                            return 10
                        } else if (birthSpan.year < 2) {
                            return 10
                        } else {
                            return birthSpan.year
                        }
                    
                    }

                    let birth = moment(this.date_birth)
                    let ageOfPerson = getAge(birth, moment());

                    if (ageOfPerson < 18 ){
                        this.birth_error = true
                    }else{
                        this.birth_error = false;
                    }

                });
                      
            },
            validateBirth(){
                // console.log(this.birth_error)
                if(this.birth_error){
                    return false
                }else{
                    return true
                }
               
            },
            uploadFiles: function () {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
                this.fileRecordsForUpload = [];
            },
            deleteUploadedFile: function (fileRecord) {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
            filesSelected: function (fileRecordsNewlySelected) {
                let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);

                // console.log(this.fileRecordsForUpload)
            },
            onBeforeDelete: function (fileRecord) {
                let i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    // queued file, not yet uploaded. Just remove from the arrays
                    this.fileRecordsForUpload.splice(i, 1);
                    let k = this.fileRecords.indexOf(fileRecord);
                    if (k !== -1) 
                        this.fileRecords.splice(k, 1);
                } else {
                    if (confirm('Are you sure you want to delete?')) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                }
            },
            fileDeleted: function (fileRecord) {
                let i = this.fileRecordsForUpload.indexOf(fileRecord);

                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }
            },
            async initiateVideoUpload () {
                // console.log('video upload')
                let at = 'b1116282ef8300ab8df6b0e2839325ec';
                //actual file is on different input
                let file = this.fileRecordsForUpload[0].file,
                    title = file.name,
                    description = this.winningStoryTxt,
                    userEmail = this.email,
                    userCity = this.city,
                    userState = this.state;
            
                //add the story details to the video upload description in case user terminates the session too early
                description = description + "-- Submitted by: " + userEmail + ", " + userCity + ", " + userState;

                if (!file) {
                    alert("No file was attached. Aborting operation.");
                    return;
                }

                var body = JSON.stringify({
                    "upload": {
                        "approach": "tus",
                        "size": file.size,
                        //   "redirect_url": "http://localhost:5500/success.html",
                    },
                    "name": title,
                    "description": description,
                    "privacy": {
                        "view": "disable",
                        "embed": "public"
                    },
                    "folder_uri": "/folders/13953272"
                });

                let xhr = new XMLHttpRequest();

                let _this = this;

                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        //   console.log(xhr);
                        _this.handleVideoUpload(xhr.response);
                    }
                }
                xhr.open('POST', 'https://api.vimeo.com/me/videos');

                xhr.setRequestHeader('Authorization', 'Bearer ' + at);
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader("Accept", "application/vnd.vimeo.*+json;version=3.4");

                xhr.send(body);
            },
            handleVideoUpload: function(res) {
                let response = res;
                let responseObj = JSON.parse(response);
                let file = this.fileRecordsForUpload[0].file;

                // console.log(responseObj);
                
                this.showVideoProgressBar = true;
                this.vimeoUrl = responseObj.link;

                //let idString = responseObj.uri;
                //let lastPosition = idString.lastIndexOf('/') + 1;

                let xhr = new XMLHttpRequest();
                let uploadToLink = responseObj.upload.upload_link;

                xhr.open("PATCH", uploadToLink);

                xhr.setRequestHeader('Tus-Resumable', '1.0.0');
                xhr.setRequestHeader('Upload-Offset', 0);
                xhr.setRequestHeader('Content-Type', 'application/offset+octet-stream');

                let _this = this;

                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        // console.log(xhr);
                        let header = this.getResponseHeader("Upload-Offset");
                        _this.checkVideoProgress(xhr.response, header);
                    }
                }

                xhr.send(file);
            },
            checkVideoProgress: function(response, header) {
                //var response = response;
                var headerVideoProgress = header;
                var fileSize = this.fileRecordsForUpload[0].file.size;
                var amountUploaded = fileSize - headerVideoProgress;

                // console.log(amountUploaded);

                if (amountUploaded === 0) {
                    this.showVideoProgressBar = false;


                    // console.log(this.vimeoUrl)

                    let getVimeoId = this.vimeoUrl.replace(/[^0-9]/g,"");

                    let formatVimeoUrl = 'https://player.vimeo.com/video/'+getVimeoId;

                    // console.log(this.vimeoUrl)
                    // console.log(getVimeoId)
                    // console.log(formatVimeoUrl)

                                            
                    // console.log('video upload finish')
                    this.formData.append('VideoUrl', formatVimeoUrl);
                    this.formData.append('File', '');
                    // console.log('formdata append')

                    this.submitWinnerStory(this.formData);
                }

                // console.log("Base size: " + fileSize + " bytes");
                
                // console.log('Check for the Upload-Offset header value, compare it to the size, if equal vid is done, if not the remainder is what is left.');
                
            },
                        
            async submit () {
                // console.log(this.fileRecordsForUpload)

                // console.log(this.v$)

                const isFormCorrect = await this.v$.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (!isFormCorrect) return

                               
                this.formData.append('FirstName', this.firstName);
                this.formData.append('LastName', this.lastName);
                this.formData.append('EmailAddress', this.email);
                this.formData.append('BirthDate', moment(this.date_birth).format('L'));
                this.formData.append('City', this.city);
                this.formData.append('State', this.state);
                this.formData.append('StoryTitle', this.storyTitle);
                this.formData.append('StoryText', this.winningStoryTxt);
                if(this.fileRecordsForUpload.length !== 0){
                    //console.log(this.fileRecordsForUpload)

                    if(['image/png','image/jpg','image/jpeg','image/svg'].includes(this.fileRecordsForUpload[0]['type'])){
                        // console.log('this is image')
                        this.formData.append('VideoUrl', '');
                        this.formData.append('File', this.fileRecordsForUpload[0].file);
                        this.submitWinnerStory(this.formData);
                        this.showVideoProgressBar = true;
                    }else{
                        // console.log('video type');
                        
                        await this.initiateVideoUpload();

                                               
                    }
                    
                }

                                              
            },
            submitWinnerStory: function(bodyFormData){

            
                // Display the key/value pairs
                for (const pair of bodyFormData.entries()) {
                // console.log(`${pair[0]}, ${pair[1]}`);
                }
                
                let apiToken = this.apiToken;

                const options = {
                    method: 'POST',
                    headers:{
                        'Authorization': 'Bearer ' + apiToken
                    },
                    body: bodyFormData,
                };

                fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/WinnerStories/Submit", options)
                .then(response => response.json())
                .then(data => {
                    // console.log(data)
                    if(data.statusCode != 400){
                        this.showVideoProgressBar = false;
                        this.successForm = true;
                    }

                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    .vdp-datepicker.error{
        color: inherit;        
    }

    .vdp-datepicker input{
        margin: 0;
    }
    .share-story-form{

        background: #f1efee;
        padding: 20px;

        .sub-header{
            margin-top: 20px;
        }

        .error {
            border-color: red;
            background: #FDD;
            font-size: inherit;
            font-weight: normal;
        }

        textarea{
            line-height: inherit;
            height: 200px;
        }

        .zipCodeError{
            display: inline;
            color: red;
            margin-left: 5px;
        }

        .custom-error-msg {
            font-size: 1.5rem;
            color: #c30;
        }

        input{
            margin: 0;
        }
    }

    select{
        line-height: inherit;
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.7);
        z-index: 1002;

        .self-building-square-spinner{
            position: absolute;
            top: 50% !important;
            left: 50%;
            margin: -50px 0 0 -50px
        }
    }

</style>