<template>
        <span>
            <span>Entries: </span>
            <select class="entriesSelect" @change="selectEntries">
                <option value="0">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
            </select>
            <button class="btn_enterDrawingPop" id="enterDrawing" data-open="modal-checkout" :disabled="!purchase" v-if="!kycMode">Enter</button>
            <button class="btn_enterDrawingPop" id="enterDrawing" data-open="modal-checkout" :disabled="!purchase" v-if="kycMode && isKYCVerified">Enter</button>
            <button class="btn_enterDrawingPop" id="enterDrawing" @click="handleClick" :disabled="!purchase" v-if="kycMode && !isKYCVerified">Enter</button>

            <div id="modal-checkout" class="reveal modal-checkout reveal-modal" data-reveal>
                <section class="entryPanel" v-if="enter">
                    <div id="drawingConfirmation" class="drawingConfirmation">
                        <div class="modalContent">
                            <h2>Confirm Drawing Entry</h2>
                            <button class="close-button" type="button" @click="closePurchase">
                                <span aria-hidden="true">×</span>
                            </button>
                            <span>
                                You have selected <strong>{{ qty }} entr<span v-if="qty < 2">y</span><span v-if="qty > 1">ies</span></strong>, for a total of <strong>{{ totalCost }} points</strong>, into the <strong>{{ name }}</strong> {{ category }} drawing to be held within 10 days of {{ parameter4 }}.
                            </span>
                        </div>
                        <div class="modalFooter cf" v-if="!transaction">
                            <button class="ProcessPaymentButton button btn btn-primary" @click="confirmPurchase">Confirm Purchase</button>
                            <a href="#" class="cancelOrder" data-close>Cancel Purchase</a>
                        </div>
                        <div class="modalFooter cf prplText" v-if="transaction">
                            Please wait while your transaction is being processed.
                        </div>
                    </div>
                </section>
                <section id="confirmPanel" class="confirmPanel" v-if="confirm">
                    <div id="errorPanel" class="errorPanel" v-if="error">
                        <div class="modalContent">
                            <h2>ERROR</h2>
                            <p>{{ message }}</p>
                        </div>
                        <div class="modalFooter cf">
                            <a href="#" class="cancelEntry" data-close>Close</a>
                        </div>
                    </div>
                    <div class="modalContent" v-else>
                        <h2>Entry Submitted</h2>
                        <span>
                            You have submitted <strong>{{ qty }} entr<span v-if="qty < 2">y</span><span v-if="qty > 1">ies</span></strong>, for a total of <strong>{{ totalCost }} points</strong>, into the <strong>{{ name }}</strong> {{ category }} drawing to be held within 10 days of {{ parameter4 }}.
                        </span>
                        <div class="modalFooter cf">
                            <a href="#" class="cancelEntry" data-close>Close</a>
                        </div>
                    </div>
                </section>
            </div>
            <div id="modal-kyc" class="reveal modal-kyc reveal-modal" data-reveal data-close-on-click="false" data-close-on-esc="false">
            <h2>Please verify your account</h2>
            <p>We have updated security for MyLotto Rewards. <strong>Please note that you will not be able to submit tickets or redeem existing points until you have completed the identity verification process.</strong></p>
            <div class="modal-kyc_actions">
                <a @click="goVerify" class="button btn-primary">Verify My Id</a>
                <button type="button" class="button btn-primary" data-close="modal-kyc">I'll do it later</button>
            </div>
        </div>
        </span>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props: [
            'data',
            'prop'
        ],
        components:{
        },
        data: function () {
            return {
                drawingId: 0,
                userId: 0,
                isUserLogged: false,
                userPoints: 0,
                pointsAvailable: false,
                category: '',
                description: '',
                image: '',
                name: '',
                parameter1: '',
                parameter2: '',
                parameter3: '',
                parameter4: '',
                parameter5: '',
                price: 0,
                productId: 0,
                skuNumber: '',
                stock: 0,
                token: {},
                user: {},
                address: '',
                purchase: false,
                qty: 0,
                totalCost: 0,
                message: '',
                enter: true,
                confirm: false,
                error: false,
                transaction: false,
                kycMode: process.env.VUE_APP_KYCMODE,
                isKYCVerified: false
            }
        },
        mounted: function () {
            this.setProps();
            let _this = this;
            (function (window, undefined) {
                'use strict';

                $('[data-reveal]').on('closed.zf.reveal', function () {
                    var modal = $(this);
                    // alert('closed');
                    _this.reset();
                });

                $(document).foundation();
            })();
            this.getKYCVerified();
        },
        methods: {
            setProps() {
                this.drawingId = this.data.drawingId;
                this.userId = this.data.userId;
                this.isUserLogged = this.data.isUserLogged;
                this.userPoints = this.data.userPoints;
                this.pointsAvailable = this.data.pointsAvailable;
                this.category = this.data.category;
                this.description = this.data.description;
                this.image = this.data.image;
                this.name = this.data.name;
                this.parameter1 = this.data.parameter1;
                this.parameter2 = this.data.parameter2;
                this.parameter3 = this.data.parameter3;
                this.parameter4 = this.data.parameter4;
                this.parameter5 = this.data.parameter5;
                this.price = this.data.price;
                this.productId = this.data.productId;
                this.skuNumber = this.data.skuNumber;
                this.stock = this.data.stock;
                this.token = this.data.token;
                this.user = this.data.user;
                this.address = this.data.address;
            },
            reset() {
                this.purchase = false;
                this.enter = true;
                this.transaction = false;
                this.confirm = false;
                this.error = false;
            },
            selectEntries(e) {
                let value = e.target.value,
                    total = this.price * value;

                this.qty = value;
                this.totalCost = total;

                this.purchase = true;

                // this.userPoints < total ? this.purchase = false : this.purchase = true;                
            },
            openPurchase() {
                let popup = new Foundation.Reveal($('#modal-checkout'));
                popup.open();
            },
            closePurchase() {
                this.enter = true;
                this.confirm = false;
                this.error = false;
                $('#modal-checkout').hide();
                $('.reveal-overlay').hide();
            },
            confirmPurchase() {
                let _this = this;
                this.transaction = true;

                let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/PerformPurchase?userId=' + this.getLoggedInUserId() + '&productId=' + this.drawingId + '&qty=' + this.qty;
            
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                .then((response) => {
                    this.transaction = false;
                    this.confirm = true;

                    if (response.status === 200) {
                        return response.json(); 
                    } else {
                        // console.log(response)
                        // if (response === null) {
                        //     console.log('NULL');
                        // } else if(response === '') {
                        //     console.log('BLANK');
                        // } else if (response === undefined) {
                        //     console.log('UNDEFINED');
                        // } else {
                            return false;
                        // }
                    }
                })
                .then((json) => {
                    let res = json;

                    if (res === false) {
                        this.message = "There was an error with your purchase. Please try again later.";
                        this.purchase = true;
                        this.enter = false;
                        this.error = true;
                    } else if (res.error === null) {
                        this.message = "Thank you for your purchase";
                        this.purchase = true;
                        this.enter = false;
                        this.confirm = true;
                        this.getUserPoints();
                    } else {
                        this.message = res.error.message;
                        this.error = true;
                    }
                })
                .catch(error => {
                    this.message = error;
                    this.enter = false;
                    this.error = true;
                    this.success = false;
                    this.purchase = true;
                })
            },
            handleClick() {
                console.log("KYC Mode is ON and isKYCVerified is False");
                this.openKYCModal();
           },
           openKYCModal() {
                let popup = new Foundation.Reveal($('#modal-kyc'));
                popup.open();
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.user.user.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.user.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                    this.isKYCVerified = json.data.userIsVerified;
                    // this.verifyLink = json.data.kycVerificationLink;
                    // this.kyc = true;

                    if (!this.kycMode) {
                        this.isKYCVerified = true;
                    }

                    console.log(`KYC : ${this.isKYCVerified}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
            goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);

            }
            },
            computed: {
            },
            watch: {
            data: function(newVal) {
                this.isUserLogged = newVal.isUserLogged,
                this.userPoints = newVal.userPoints,
                this.pointsAvailable = newVal.pointsAvailable,
                this.category = newVal.category,
                this.description = newVal.description,
                this.image = newVal.image,
                this.name = newVal.name,
                this.parameter1 = newVal.parameter1,
                this.parameter2 = newVal.parameter2,
                this.parameter3 = newVal.parameter3,
                this.parameter4 = newVal.parameter4,
                this.parameter5 = newVal.parameter5,
                this.price = newVal.price,
                this.productId = newVal.productId,
                this.skuNumber = newVal.skuNumber,
                this.stock = newVal.stock,
                this.token = newVal.token,
                this.user = newVal.user,
                this.address = newVal.address
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>