<template>
    <div class="checkNumbersModule">
        <h2>Check your numbers</h2>
        <div class="check-numbers-selector CheckYourNumbersBoxes"  v-if="hideCheckPanels">
        
            <ol class="textFullPage">
                <li>1. Select a Game</li>
                <li>2. Pick a Date</li>
                <li>3. Enter Your Numbers</li>
            </ol>
            <div class="text1"></div>
            <div class="check-panels box-1">

                <ul class="checkNumbersGameList">
                    <li class="megamillions" data-game="MegaMillions" @click="clkGame($event, 1)" :class="{ selected : active_el == 1 }">
                        <a :class="MegiplierStatus === 'true' ? 'multiplier' : ''">Megamillions</a>
                    </li>
                    <li class="powerball" data-game="Powerball" @click="clkGame($event, 2)" :class="{ selected : active_el == 2 }">
                        <a>Powerball</a>
                    </li>
                    <li class="pick3" data-game="Pick3" @click="clkGame($event, 3)" :class="{ selected : active_el == 3 }">
                        <a>Pick3</a>
                    </li>
                    <li class="pick4" data-game="Pick4" @click="clkGame($event, 4)" :class="{ selected : active_el == 4 }">
                        <a>Pick4</a>
                    </li>
                    <li class="pick5" data-game="Pick5" @click="clkGame($event, 5)" :class="{ selected : active_el == 5 }">
                        <a>Pick5</a>
                    </li>
                    <li class="rollingcash" data-game="RollingCashFive" @click="clkGame($event, 6)" :class="{ selected : active_el == 6 }">
                        <a>Rolling Cash 5</a>
                    </li>
                    <li class="lucky4life" data-game="LuckyForLife" @click="clkGame($event, 7)" :class="{ selected : active_el == 7 }">
                        <a>Lucky For Life</a>
                    </li>
                    <li class="classiclotto" data-game="ClassicLotto" @click="clkGame($event, 8)" :class="{ selected : active_el == 8 }">
                        <a>Classic Lotto</a>
                    </li>
                </ul>
            </div>
            <div class="text2"></div>
            <div class="check-panels box-2">
                <datepicker
                    class="check-datepicker"
                    :inline="true"
                    name="inputPickDate"
                    :format="customFormatter"
                    v-model="selectedDate"
                    :disabledDates="disabledDates"
                    :highlighted="state.highlighted"
                    >
                </datepicker>
            </div>
            <div class="text3"></div>
            <div class="check-panels box-3">
                <div class="enter-numbers cyn_numbers_wrap" :class="gameName">
                    
                    <div v-for="(number,index) in numbers" v-bind:key="index" class="number_input_wrapper">
                        <input type="text" pattern="\d*" name="number" v-model="number.value" :class="[`numberPos-${index}`]" class="number_input" maxlength="2"  /> 
                    </div>
                    <!-- Need to add Midday/Evening options for Pick 3, 4, 5 -->

                    <div class="middayEvenning" v-if="gameName == 'Pick3' || gameName == 'Pick4' || gameName == 'Pick5'">
                        
                        <div class="midday">
                            <label for="Time_0"><input name="time" id="Time_0" value="1" type="radio" v-model="modifier"> Midday</label>
                        </div>
                        <div class="evening">
                            <label for="Time_1"> <input name="time" id="Time_1" value="2" type="radio" v-model="modifier"> Evening</label>
                        </div>
                    </div>

                </div>

                <div class="btn-go">
                    <button @click="submit" class="button">Submit</button>
                </div>
            </div>
        

            <div class="cell small-12 errMsg callout alert" data-closable  v-if="showErrorMessage">
                <p>{{ messageErrorTxt }}</p>
            </div>

            

            


            
        </div>
        
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#0FD8C9"
            v-if="isLoading"
        />

        <div class="results" v-if="showMatchNumbers">
            <div class="bigBox">
                <div class="enter-numbers drawingImage">
                    <div :class="gameName+'_logo'" class="drawingNameImage">
                        <img v-bind:src="gameLogo" v-bind:alt="gameName" class="winNumGameLogo" />
                    </div>
                    <span class="drawingName">{{printGameName}}</span>:  
                    <span class="drawingDate">{{ customFormatter(selectedDate) }}</span>
                </div>
        
                <div class="enter-numbers cyn_numbers_wrap">
                    <div class="yourNumbers">
                        <p>Your Numbers:</p>
                        <div class="numbers"  :class="gameName">
                            <div v-for="(number,index) in numbers" v-bind:key="index" class="number_input_wrapper">
                                <input type="text" name="number" v-model="number.value" maxlength="2" class="number_input" readonly /> 
                            </div>
                        </div>
                    </div>

                    <div class="cyn_results">
                        <p>Winning Numbers:</p>
                        <div class="numbers" :class="'winningInputs_'+gameName">
                            <div v-for="(wNumber, index) in winningNumbers" v-bind:key="index" class="winningNumbersInputs number_input_wrapper" :class="[`winningNumbersInputs-${index}`]">
                                <input type="text" name="number" :value="wNumber" :class="[`numberPos-${index}`, `input-${index}` ]" class="number_input" readonly /> 
                            </div>

                        </div>

                        <div class="special-number extraInfo" v-if="gameName == 'MegaMillions'">
                            <span class="extraLabel">Megaplier:</span> <span class="extraVal">{{ megaSpecialValue }}</span>
                        </div>

                        <div class="special-number extraInfo" v-if="gameName == 'Powerball'">
                            <span class="extraLabel">Power Play:</span> <span class="extraVal">{{ powerSpecialValue }}</span>
                        </div>

                        <div class="middayEveningInfo" v-if="gameName == 'Pick3' || gameName == 'Pick4' || gameName == 'Pick5'">
                            <p>
                                <span v-if="modifier == 1">Midday</span>
                                <span v-if="modifier == 2">Evening</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="btn-go">
                <button class="button" @click="checkMoreNumbers"> Check More Numbers </button>
            </div>

            <div class="resultsText winText" v-if="winnerMessage">
                <h2>Congratulations!</h2>
                <!-- <p v-if="prizeName.length"> {{ prizeName }}</p>
                <p>Winner Prize: {{ winnerPrize }}</p> -->
                <p>It looks like you may have a winner. Please verify that the drawing date matches the drawing date on your ticket. 
                To find out how to claim your prize, <a href="/Claiming/How-To-Claim" target="_blank">click here</a>. </p>
            </div>

            <div class="resultsText loseText" v-if="notWinnerMessage">
                <h2>Better Luck Next Time!</h2>
                <p>We're sorry, but your numbers did not win any prizes for this game. </p>
            </div>

        </div>

    </div>
</template>


<script>
import Datepicker from "vue-moment-datepicker";
import { HollowDotsSpinner } from 'epic-spinners'
import moment from 'moment';

export default{
    components: {
        Datepicker,
        HollowDotsSpinner
    },
    data(){
        return{
            gameName: '',
            printGameName: '',
            gameLogo: '',
            numbers: [],
            modifier: 1,
            winningNumbers: [],
            winnerPrize: '',
            prizeName: '',
            numberOfNumbers: 5,
            selectedDate: '',
            showErrorMessage: false,
            messageErrorTxt: '',
            winnerMessage: false,
            notWinnerMessage: false,
            winnerMessageTxt: '',
            showMatchNumbers: false,
            hideCheckPanels: true,
            megaSpecialValue: '',
            powerSpecialValue: '',
            classicSpecialValue: '',
            active_el: 0,
            isLoading: false,
            disabledDates: {
                from: new Date()
            },
            state: {
                highlighted: {
                    dates: [new Date()],
                    includeDisabled: true // Highlight disabled dates
                }
            },
            MegiplierStatus: this.getFeatureSwitch().megaplier,
        };
    },

    mounted(){
        let number = []

        for(var i=0; i < this.numberOfNumbers; i++){
            number.push({
                "value": undefined,
                "index": i
            })
        }

        this.numbers = number;

    },
    methods:{
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        updateInputs(){
            let number = []

            for(var i=0; i < this.numberOfNumbers; i++){
                number.push({
                    "value": undefined,
                    "index": i
                })
            }

            this.numbers = number;
        },
        clkGame(event, el){
           this.active_el = el;
           this.gameName = event.currentTarget.getAttribute('data-game')
           switch (this.gameName) {
            case 'MegaMillions':
                this.modifier = "0";
                this.numberOfNumbers = 6;
                this.updateInputs();
                break;
            case 'Powerball':
                this.modifier = "0";
                this.numberOfNumbers = 6;
                this.updateInputs()
                break;
            case 'Pick3':
                this.numberOfNumbers = 3;
                this.updateInputs()
                break;
            case 'Pick4':
                this.numberOfNumbers = 4;
                this.updateInputs()
                break;
            case 'Pick5':
                this.numberOfNumbers = 5;
                this.updateInputs()
                break;
            case 'RollingCashFive':
                this.modifier = "0";
                this.numberOfNumbers = 5;
                this.updateInputs()
                break;
            case 'LuckyForLife':
                this.modifier = "0";
                this.numberOfNumbers = 6;
                this.updateInputs()
                break;
            case 'ClassicLotto':
                this.modifier = "0";
                this.numberOfNumbers = 6;
                this.updateInputs()
            }
        },
        submit(){
           this.getToken();
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
            .then((token) => {
                this.fetchData(token);
            });
        },
        fetchData(token) {
            var apiToken = token;
            var gameNameApi = this.gameName;

            // console.log(this.gameName)

            
            let formatDate = moment(this.selectedDate).format('yyyy-MM-DD');
            // console.log(formatDate)
            var bodyApi = {
                "date": formatDate,
                "numbers": this.numbers,
                "modifier": this.modifier
            }


            const requestOptions = {
                method: 'POST',
                headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+apiToken
                },
                body: JSON.stringify(bodyApi)
            };
            fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/" + gameNameApi +"/CheckYourNumbers", requestOptions)
                .then(response => response.json())
                .then(data => {
                    let resultsArray = data.data;

                    // console.log(data)

                    if( resultsArray === null){
                        this.showErrorMessage = true;
                        this.messageErrorTxt = data.error.message
                        return false
                    }else{
                        this.showErrorMessage = false;
                        const userNumbers = []
                        this.numbers.forEach(function (item) {
                            userNumbers.push(parseInt(item.value))
                        });

                        this.isLoading = true;

                        
                        //this.fetchLogo(apiToken);

                        return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/"+gameNameApi+"/GetGameInformation?getAllGameInfo=true", {
                            headers: {
                                'Authorization': 'Bearer '+apiToken,
                            },
                        })
                        .then(response => response.json())
                        .then(json => {
                                var gameData = json.data;
                                this.gameLogo = gameData.gameLogoURL;
                                this.printGameName = gameData.name;

                                this.hideCheckPanels = false;
                                this.isLoading = false;
                                this.showMatchNumbers = true;
                                this.winningNumbers = resultsArray.winningNumbers;

                                let result;

                                // console.log(userNumbers)

                                const matchNumbers = function (a1, a2) {
                                    let result = [];
                                    let longerLength = a1.length >= a2.length ? a1.length : a2.length;
                                    for (var i = 0; i < longerLength; i++){
                                        if (a1[i] === a2[i]) {
                                            result.push(i);
                                        }
                                    }
                                    return result;
                                };

                                const restultOfMatch = matchNumbers(userNumbers, resultsArray.winningNumbers)
                                // console.log(restultOfMatch);

                                fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/" + gameNameApi +"/GetGameInformation",  {
                                    headers: {
                                        'Authorization': 'Bearer '+apiToken,
                                    },
                                })
                                .then(response => response.json())
                                .then(data => {
                                    // console.log(this.gameName)
                                    let res = data;
                                    let prizes = {};
                                    // console.log(res)
                                    prizes = res.data.prizeStructureJSON;
                                    let toJson = eval('('+prizes+')' );
                                    let gameRules = {};
                                    if(this.gameName == 'Powerball'){
                                        gameRules = toJson.Matches
                                    }else{
                                        gameRules = toJson.Rules
                                    }
                                    // console.log(res)
                                    // console.log(prizes)

                                    let getAllInputs = document.querySelectorAll('.winningNumbersInputs input');

                                    Array.from(getAllInputs).forEach(function(el){
                                        if (el.classList.contains('winner-number')) {
                                            el.classList.remove('winner-number')
                                        }
                                    })

                                    for (var i = 0; i < restultOfMatch.length; i++) {
                                        let correctResults = document.getElementsByClassName('input-' + restultOfMatch[i] )
                                        for (var j = 0; j < correctResults.length; j++) {
                                            correctResults[j].classList.add("winner-number");
                                            // console.log(correctResults[j])
                                            correctResults[j].insertAdjacentHTML("afterend", "<span class='winner-check-number'></span>");
                                        }
                                    }


                                    if(this.gameName == 'MegaMillions' || 
                                    this.gameName == 'Powerball'    || 
                                    this.gameName == 'LuckyForLife' || 
                                    this.gameName == 'ClassicLotto' ||
                                    this.gameName == 'RollingCashFive'){
                                        let context = document.querySelector('.winningInputs_'+this.gameName+' .winningNumbersInputs-6');
                                        let specialBallInput = document.querySelector('.winningInputs_'+this.gameName+' .winningNumbersInputs-5 input');
                                        let specialValue = '';

                                        if(context !== null){
                                            specialValue = context.querySelector('input').value;

                                            if( this.gameName !== 'MegaMillions'){
                                                this.powerSpecialValue = specialValue;
                                            }else{
                                                this.megaSpecialValue = specialValue
                                            }

                                            
                                        }

                                        if( this.gameName == 'MegaMillions' || 
                                            this.gameName == 'Powerball'    || 
                                            this.gameName == 'LuckyForLife' ||
                                            this.gameName == 'RollingCashFive'){

                                            if(this.gameName !== 'RollingCashFive')  {
                                                specialBallInput.classList.remove('numberPos-5')
                                                specialBallInput.classList.add('special-ball')
                                            }  
                                            

                                            let prizeJsonMatch = {
                                                WB: 0, 
                                                CB: 0
                                            };

                                            if(document.querySelector('.winningInputs_'+this.gameName+' .winningNumbersInputs-6')){
                                                document.querySelector('.winningInputs_'+this.gameName+' .winningNumbersInputs-6').remove();
                                            }

                                            const classPos = document.querySelectorAll(".winningInputs_"+this.gameName+" input[class*='numberPos']");
                                            const inputFilter = (classPos) => classPos.classList.contains('winner-number');
                                            const winnerWB = [...classPos].filter(inputFilter);
                                            // console.log(winnerWB.length);
                                            prizeJsonMatch.WB = winnerWB.length;

                                            if(this.gameName !== 'RollingCashFive')  {
                                                // console.log('not rolling', this.gameName)

                                                let findSpecialBall = Array.from(document.querySelector('.cyn_results .winningInputs_'+this.gameName+' .special-ball').classList);

                                                if ( findSpecialBall.includes('winner-number') ){
                                                    prizeJsonMatch.CB = 1
                                                    //document.querySelector('.winningInputs_'+this.gameName+' .special-ball')
                                                }

                                                // Array.from(document.querySelector('.win_numbers .winningInputs_'+this.gameName+' .special-ball').classList).forEach(function(el){
                                                //     console.log( el )
                                                //     if( el.includes('winner-number') ){ 
                                                //         console.log('cb 1')
                                                //         prizeJsonMatch.CB = 1
                                                        
                                                //     }else{
                                                //         console.log('cb 0')
                                                //         prizeJsonMatch.CB = 0
                                                //     }
                                                // })
                                            }


                                            // console.log(gameRules)
                                            // console.log(prizeJsonMatch)

                                            var dataRules = gameRules,
                                                search = prizeJsonMatch,
                                                result = dataRules.filter(function (o) {
                                                    return Object.keys(search).every(function (k) {
                                                        return o[k] === search[k];
                                                    });
                                                });
                                        }

                                        if (this.gameName === 'ClassicLotto') {
                                            let prizeJsonMatch = {
                                                WB: 0
                                            };

                                            // gather the winning numbers and check for marked winners
                                            let classSelector = "."+this.gameName+" .amIaWinner_form .winning-numbers [class*='numberPos']";
                                            // console.log("classSelector: "+classSelector);
                                            const classPos = document.querySelectorAll(".winningInputs_"+this.gameName+" input[class*='numberPos']");
                                            const inputFilter = (classPos) => classPos.classList.contains('winner-number');
                                            const winnerWB = [...classPos].filter(inputFilter);
                                            // console.log(winnerWB.length);
                                            prizeJsonMatch.WB = winnerWB.length;

                                            //console.log(winnerWB)
                                            //console.log("winnerWB count: "+winnerWB.length);
                                            for(let n = 0; n < winnerWB.length; n++){
                                            // console.log(winnerWB[n])
                                                winnerWB[n].insertAdjacentHTML("afterend", "<span class='winner-number'></span>");
                                            }
                                            prizeJsonMatch.WB = winnerWB.length;

                                            
                                            // console.log(prizeJsonMatch)

                                            var dataRules = gameRules,
                                                search    = prizeJsonMatch,
                                                result    = dataRules.filter(function (o) {
                                                    return Object.keys(search).every(function (k) {
                                                        // console.log(o[k])
                                                    return o[k] === search[k];
                                                });
                                            });

                                            // console.log(dataRules)
                                        }
                                    }

                                    if ( this.gameName.indexOf('Pick') >= 0 ){

                                        const classPos = document.querySelectorAll(".winningInputs_"+this.gameName+" input[class*='numberPos']");
                                        const inputFilter = (classPos) => classPos.classList.contains('winner-number');
                                        const winnerPicks = [...classPos].filter(inputFilter);
                                    
                                        let arrOfPicks = [];
                                        let arrOfRulesPosition = [];
                                        
                                        let filterByMatchPosition = gameRules.filter(function(v){ return v.MatchPositions == true});
                                        // console.log(winnerPicks)
                                        for(let i=0; i < winnerPicks.length; i++){
                                            let getWinPos = parseInt(winnerPicks[i].classList[1].replace(/[^0-9]/g,'') );

                                            // console.log('winner pos ', getWinPos)

                                            arrOfPicks.push(getWinPos)

                                        }

                                        for( let k = 0; k < filterByMatchPosition.length; k++ ){
                                            let getRulesPositions = filterByMatchPosition[k].Positions;
                                            arrOfRulesPosition.push(getRulesPositions)

                                        }

                                        
                                        // console.log(filterByMatchPosition)
                                        // console.log(arrOfRulesPosition)
                                        // console.log(arrOfPicks)

                                        let set2 = new Set(arrOfPicks),
                                        resultPickGames = filterByMatchPosition.filter(({ Positions }) =>
                                            Positions.length === set2.size && Positions.every(Set.prototype.has, set2));

                                        // console.log(resultPickGames);

                                        if(resultPickGames && resultPickGames.length > 0){
                                            this.winnerMessage = true;
                                            this.winnerPrize = resultPickGames[0].Prize
                                            this.prizeName = resultPickGames[0].Name
                                            this.notWinnerMessage = false;

                                            return
                                        }else{
                                            this.winnerMessage = false;
                                            this.notWinnerMessage = true;

                                            return
                                        }
                                    }


                                    // console.log(resultsArray)
                                    // console.log(result);
                                    // console.log(res);

                                    if(result && result.length > 0){
                                        this.winnerMessage = true;
                                        this.winnerPrize = result[0].Prize
                                        this.notWinnerMessage = false;
                                    }else{
                                        this.winnerMessage = false;
                                        this.notWinnerMessage = true;
                                    }


                                })

                                if(resultsArray.winner){
                                    this.winnerMessage = true;
                                    this.notWinnerMessage = false;
                                }else{
                                    this.winnerMessage = false;
                                    this.notWinnerMessage = true;
                                }

                                })
                                .catch(error => {
                                    // console.error("There was an error!", error);
                                    return this.errorMessage = error;
                                });

                        
                    }

                    

                    
                    
                }).catch(error => {
                    // console.error("There was an error!", error);
                    // console.log(error)
                    this.showErrorMessage = true;
                    this.messageErrorTxt = 'There does not appear to be a drawing for the selected date. Please select another date and try again.'
                })
        },
        checkMoreNumbers(){
            this.hideCheckPanels = true;
            this.showMatchNumbers = false;
            this.notWinnerMessage = false;
            this.winnerMessage = false;
        }
    }
}



</script>


<style lang="scss" scoped>

.middayEvenning{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;

    label{
        display: flex;
        justify-content: center;
        align-items: center;

        input{
            height: auto;
            margin: 0 15px 0 0;
        }
    }
}

.CheckYourNumbersBoxes{
    .errMsg{
        margin-top: 20px;
    }
}



</style>