<template>
    <div>

        <div class="poll-widget">
            <div class="poll-wrapper">

                <div>

                    <span v-if="errorMsg">{{ errorMsg }}</span>

                    <span class="poll-question">{{ pollQuestion }}</span>

                    <div class="poll-answers">
                        <div class="poll-list" :key="updatePoll">
                            <div class="poll-answer" v-for="answer in pollAnswers" :key="answer.id"
                                :id="answer.answerQuestionID" :answer-id="answer.answerID">

                                <label :class="!isUserLogged && !isUserCanVote || isUserLogged && !isUserCanVote? 'disabled' : ''">
                                    <input type="radio" :value="answer.answerText" :id="answer.answerID" name="radio"
                                        @change="selectedRadioAnswer($event)" v-if="isUserLogged && isUserCanVote" />
                                    {{ answer.answerText }}
                                </label>
                            </div>

                        </div>
                    </div>

                    <div class="poll-footer">
                        <div v-if="showVoteBtn">
                            <button type="button" class="button btn-default" @click="submitPoll"
                                v-if="isUserLogged && isUserCanVote && !kycMode">{{ submitText }}</button>
                                <button type="button" class="button btn-default" @click="submitPoll"
                                v-if="isUserLogged && isUserCanVote && kycMode && isKYCVerified">{{ submitText }}</button>
                                <button type="button" class="button btn-default" @click="handleClick"
                                v-if="isUserLogged && isUserCanVote && kycMode && !isKYCVerified">{{ submitText }}</button>
                            <p class="pollAlert" v-if="!isUserLogged">
                                <a href="#" class="btn_signIn btn_popupLogIn" id="globalSignIn">Sign In</a> or <a href="/Account/Register">Register</a> to
                                Vote.
                            </p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div id="modal-kyc" class="reveal modal-kyc reveal-modal" data-reveal data-close-on-click="false" data-close-on-esc="false">
            <h2>Please verify your account</h2>
            <p>We have updated security for MyLotto Rewards. <strong>Please note that you will not be able to submit tickets or redeem existing points until you have completed the identity verification process.</strong></p>
            <div class="modal-kyc_actions">
                <a @click="goVerify" class="button btn-primary">Verify My Id</a>
                <button type="button" class="button btn-primary" data-close="modal-kyc">I'll do it later</button>
            </div>
       </div> 
    </div>
</template>
<script>

export default {
    props:{
        surveyIdProp:Number,
        submitTextProp:String,
        showStatsProp:String,
        fetchStats:Function,
        pollQuestion:String,
        pollAnswers:Array,
        loadComponent:Function,
        goVerify:Function,
        isUserCanVote:Boolean,
        kycMode:Boolean,
    },
    data: function () {
        return {
            isUserLogged: this.isUserAuthed(),
            isUserVoted: false,
            updatePoll: 0,
            answerData:[],
            questionId: '',
            answerID: '',
            responseText: '',
            submitText: 'Submit',
            errorMsg: '',
            globalToken: '',
            showVoteBtn: true,
            isDisabled: false,
            displayStats: false
        }
    },
    mounted () {

        let apiToken = this.$cookies.get('apiToken');
        console.log("current user ",apiToken.user.username)
        if (apiToken.user.username == "mobilepublic@mtllc.com") {
            this.isDisabled = true;
            if (this.showStatsProp == 'Always') {
                 this.fetchStats();
                 }
        }else this.isDisabled = false;

        if ( this.submitTextProp === '') {
            this.submitText = 'Submit'
        } else{
            this.submitText = this.submitTextProp;
        } 
    },
    methods: {
        selectedRadioAnswer(event) {
            let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.id;
            let getAnswerId = event.currentTarget.id;
            let getQuestionText = event.currentTarget.value;
            this.questionId = getIdOfQuestionWrapper;
            this.answerID = getAnswerId;
            this.responseText = getQuestionText;
        },
        submitPoll() {
            console.log("surveyid = ",this.surveyIdProp)
            let currentuserID;
            if (this.globalUser == undefined || this.globalUser == null) {
                var getCurrentUser = this.$cookies.get('apiToken');
                currentuserID = getCurrentUser.user.userId;
            } else {
                //coming from mobile app / no cookies
                currentuserID = this.globalUser;
            }

            let submitJson = {
                surveyID: parseInt(this.surveyIdProp),
                userId: parseInt(currentuserID),
                questionId: parseInt(this.questionId),
                answerId: parseInt(this.answerID),
                responseText: this.responseText
            }


            const urlProtocol = 'https:';
            let apiToken = this.$cookies.get('apiToken').token;
            console.log("token ", apiToken)
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(submitJson)
            };
            fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/SubmitAnswer", requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.statusCode === 200) {
                        this.showSurvey = false
                        this.displaySuccessMsg = true
                        this.showBack = true;
                        this.isUserCanVote = false;
                    }
                    this.showVoteBtn = false;
                    this.fetchStats();
                    // if (this.widgetRecentPostsDisplays === 'After-Participation' || this.g) {
                    //     this.fetchStats();
                    // }

                    // if (this.widgetRecentPostsDisplays === 'Always') {
                    //     this.fetchStats();
                    // }
                })
        }
    },
    computed: {

    },
};
</script>

<style lang="scss" scoped></style>