<template>
    <section class="tabbed_content fluid_tabs cf contentRow" id="TransactionLogPage">
        <UserLoginVue v-if="!isUserLogged"></UserLoginVue>
        <div v-if="isUserLogged">
            <div class="tabbed_content mlrCouponTabs fluid_tabs cf">
                <ul class="tab_nav cf">
                    <li :class="{'selected':active}">
                        <a href="#1" id="tab_1" @click="switchView">Active Digital Purchases</a>
                    </li>
                    <!-- <span></span> -->
                    <li :class="{'selected':redeemed}">
                        <a href="#2" id="tab_2" @click="switchView">Redeemed Digital Purchases</a>
                    </li>
                </ul>
                <div class="tab_content_wrap first_active cf" id="tab_content_block">
                    <div class="tab_content current_content" id="tab_content_1">
                        <ul class="myDigitalOrdersList" v-if="active && activeOrders.length">
                            <li class="digitalOrderListItem" v-for="product in activeOrders" :key="product.associatedOrderId">
                                <div class="digitalOrderInfo">
                                    <a :href="'/mylotto-rewards/my-account/my-digital-purchases/digital-purchases-details?orderId=' + product.orderID" class="giftCardTitle">
                                        {{ product.orderItems[0].name }}
                                    </a>
                                    <span class="giftCardCode" v-if="product.fulfillmentInfo">{{ product.fulfillmentInfo.digitalFulfillmentInfo.giftCardCode }}</span>
                                    <span class="giftCardPurchDate">{{ product.orderDate }}</span>
                                    <a href="#" class="btn_orderRedeemed" data-open="redeemOrderModal" @click="openRedeem(product)">Mark Redeemed</a>
                                </div>
                            </li>
                        </ul>
                        <div class="noActiveOrders" v-if="active && activeOrders.length < 1">
                            <p>You don't have any active Digital Purchases.</p>
                            <a class="button btn_purple" href="/mylotto-rewards/redemption-central/digital-catalog">SHOP THE DIGITAL CATALOG</a>
                        </div>
                    </div>
                </div>

                <div class="tab_content current_content" id="tab_content_2">
                    <div class="redeemedHeader snippOrders" v-if="!active && redeemedOrders.length">
                        <span class="headerPname">Product Name</span>
                        <span class="headerOrdCode">Redemption Code</span>
                        <span class="headerPdate">Purchase Date</span>
                        <span class="headerMasR">Mark as Not Redeemed</span>
                    </div>
                    <ul class="redeemedOrders" v-if="!active && redeemedOrders.length">
                        <li class="digitalOrderListItem" v-for="product in redeemedOrders" :key="product.associatedOrderId">
                            <div class="digitalOrderInfo">
                                <span class="giftCardTitle">
                                    {{ product.orderItems[0].name }}
                                </span>
                                <span class="giftCardCode">{{ product.fulfillmentInfo.digitalFulfillmentInfo.giftCardCode }}</span>
                                <span class="giftCardPurchDate">{{ product.orderDate }}</span>
                                <a href="#" class="btn_orderRedeemed" data-open="redeemOrderModal" @click="openRedeem(product)">Mark Not Redeemed</a>
                            </div>
                        </li>
                    </ul>
                    <div class="noActiveOrders" v-if="!active && redeemedOrders.length < 1">
                        <p>You don't have any redeemed Digital Purchases.</p>
                        <a class="button btn_purple" href="/mylotto-rewards/redemption-central/digital-catalog">SHOP THE DIGITAL CATALOG</a>
                    </div>
                </div>
            </div>
            
            <div id="redeemOrderModal" class="reveal reveal-modal data-modalOptions" data-reveal>
                <span v-if="active && !error">
                    <h3>Did you redeem this Digital Purchase?</h3>
                    <p>If you have redeemed it, tap YES to remove it from your Active list.</p>
                    <div class="orderModalOptions">
                        <a class="button btn-purple btn-secondary" data-close @click="closeRedeem">NO, KEEP ACTIVE</a>
                        <button type="button" class="button btn-primary redeemOrder" data-close @click="redeemDigitalItem(true)">YES, I REDEEMED IT</button>
                    </div>
                </span>
                <span v-if="!active && !error">
                    <h3>Did you redeem this Digital Purchase?</h3>
                    <p>If you have not redeemed it, tap YES to remove it from your Redeemed list.</p>
                    <div class="orderModalOptions">
                        <a class="button btn-purple btn-secondary" data-close @click="closeRedeem">NO, I REDEEMED IT</a>
                        <button type="button" class="button btn-primary unredeemOrder" data-close @click="redeemDigitalItem(false)">YES, IT'S NOT REDEEMED</button>
                    </div>
                </span>
                <span v-if="error">
                    <h3>Error</h3>
                    <p>We're worry there was an error with your transaction. Please try again later.</p>
                </span>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from "moment";
    import Pagination from '../Pagination/pagination.vue';
    import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
           Pagination,
           UserLoginVue
        },
        data: function () {
            return {
                isUserLogged: this.isUserAuthed(),
                mobileCashing: false,
                token: '',
                user: '',
                activeOrders: '',
                redeemedOrders: '',
                points: 0,
                disable: true,
                totalPages: 10,
                itemsPerPage: 10,
                currentPage: 1,
                currentItems: '',
                active: true,
                redeemed: false,
                itemChosen: '',
                error: false
            }
        },
        mounted: function () {
            this.getToken();

            this.startDate = moment().startOf('month').format('yyyy-MM-DD');
            this.endDate = moment().format('yyyy-MM-DD');
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    //this.apiOrders(true);
                    this.apiOrders(false);
                });
            },
            getOrders() {
                this.apiOrders(true);
                this.apiOrders(false);
            },
            apiOrders(redeemed) {
                let apiToken = this.token,
                    red = redeemed,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderListFilter',
                    body = {
                        UserId: this.user.user.userId,
                        RecordsPerPage: 1000,
                        PageNumber: 1,
                        StartDate: '2000-01-01T00:00:00Z',
                        EndDate: '2030-12-31T23:59:59Z', 
                        Type: 3,
                        Redeemed: red,
                    };
                            
                return fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken,
                    },
                    body: JSON.stringify(body),
                })
                .then((response) => response.json())
                .then((json) => {
                    let d = json.data,
                        active = [],
                        fulfilled = [];

                    for (let i = 0; i < d.length; i++) {
                        let data = d[i];

                        let date = moment(new Date(data.orderDate)).format('MM/DD/YYYY');

                        data.orderDate = date;

                        if (data.fulfillmentInfo === null) {
                            active.push(data);
                        } else if (data.fulfillmentInfo.digitalFulfillmentInfo.redeemed === false) {
                            active.push(data);
                        } else if (data.fulfillmentInfo.digitalFulfillmentInfo.redeemed === true) {
                            fulfilled.push(data);
                        }
                    }

                    this.activeOrders = active;
                    this.redeemedOrders = fulfilled;

                    // console.log(this.redeemedOrders)
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            openRedeem(product) {
                this.error = false;
                this.itemChosen = product;
                // console.log(this.itemChosen);
                // let popup = Foundation.Reveal($('#redeemOrderModal'));
                // popup.open();
            },
            redeemDigitalItem(d) {
                let item = this.itemChosen,
                    apiToken = this.token;

                let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/RedeemDigitalItem?userId=' + this.user.user.userId + '&productId=' + item.orderItems[0].skuid + '&orderId=' + item.orderItems[0].id + '&redeemed=' + d;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.data === true) {
                        this.getOrders();
                        this.closeRedeem();
                    } else {
                        this.error = true;
                    }
                })
           },
           closeRedeem() {
                this.enter = true;
                this.confirm = false;
                this.error = false;
            },
            switchView() {
                this.active = !this.active;
                this.redeemed = !this.redeemed;
            },
            setPages() {
                this.itemsPerPage = 5;
                this.totalPages = Math.ceil(this.tLog.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.tLog.slice(start, end);

                this.currentItems = currentItems;
            },
            setStartDate(t) {
                let target = t;

                this.startDate = moment(target.target.value).format('yyyy-MM-DD');
            },
            setEndDate(t) {
                let target = t;

                this.endDate = moment(target.target.value).format('yyyy-MM-DD');
            }
		}
    };
</script>