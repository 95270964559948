<template>
    <div class="check_your_numbers_panel" :class="gameName">
        <h3>How Frequently Are Individual Numbers Drawn?</h3>
        <div class="panel_content">
            <div class="numFreq_form grid-x">
                <div class="cell small-5">
                    <select class="dateRange_select" @change="changeRange($event)">
                        <option value="0">Select Date Range</option>
                        <option value="7">7 Days</option>
                        <option value="30">30 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">1 Year</option>
                        <option value="730">2 Years</option>
                        <option value="12345">5/17/2002</option>
                    </select>
                </div>

                <div class="cell small-5">
                    <select class="number_select" @change="changeNumber($event)">
                        <option value="0">Select Number</option>
                        <option v-for="val in numbersArray" :value="val" v-bind:key="val">{{ val }}</option>
                        <option value="-1">All</option>

                    </select>
                </div>

                <div class="cell small-2">
                    <button class="button" @click="go">Go</button>
                </div>

                <!-- <button @click="go">Go</button> -->
            </div>

            <div class="numFreq_results grid-x" v-if="showResults">
                <table cellspacing="0" cellpadding="0" border="0" class="number_frequency_results">
                    <thead>
                        <tr class="number_frequency_results_header" v-if="game === 'megaMillions'">
                            <th class="numFreq_number">Ball Number</th>
                            <th class="numFreq_freq">Frequency</th>
                            <th class="">Mega Ball Frequency</th>
                            <th class="" v-if="this.MegiplierStatus === 'true'">Megaplier Frequency</th>
                        </tr>
                        <tr class="number_frequency_results_header" v-if="game === 'powerBall'">
                            <th class="numFreq_number">Ball Number</th>
                            <th class="numFreq_freq">Frequency</th>
                            <th class="">Powerball Frequency</th>
                            <th class="">Powerplay Frequency</th>
                        </tr>
                        <tr class="number_frequency_results_header" v-if="game === 'luckyForLife'">
                            <th class="numFreq_number">Ball Number</th>
                            <th class="numFreq_freq">Frequency</th>
                            <th class="">Lucky Ball Frequency</th>
                        </tr>
                        <tr class="number_frequency_results_header"
                            v-if="game === 'classicLotto' || game === 'rollingCashFive' || game === 'pick3' || game === 'pick4' || game === 'pick5'">
                            <th class="numFreq_number">Ball Number</th>
                            <th class="numFreq_freq">Frequency</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="numFreq_results_row" v-if="game === 'megaMillions'"
                            v-for="(item, index) in individualNumbers" :key="index">
                            <td class="numFreq_number">{{ item.number }}</td>
                            <td class="numFreq_freq">{{ item.whiteBallFreq }}</td>
                            <td class="">{{ item.megaBallFreq }}</td>
                            <td class="" v-if="MegiplierStatus === 'true'">{{ item.megaplierFreq }}</td>
                        </tr>
                        <tr class="numFreq_results_row" v-if="game === 'powerBall'"
                            v-for="(item, index) in individualNumbers" :key="index">
                            <td class="numFreq_number">{{ item.number }}</td>
                            <td class="numFreq_freq">{{ item.whiteBallFreq }}</td>
                            <td class="">{{ item.powerballFreq }}</td>
                            <td class="">{{ item.powerplayFreq }}</td>
                        </tr>
                        <tr class="numFreq_results_row" v-if="game === 'luckyForLife'"
                            v-for="(item, index) in individualNumbers" :key="index">
                            <td class="numFreq_number">{{ item.number }}</td>
                            <td class="numFreq_freq">{{ item.whiteBallFreq }}</td>
                            <td class="">{{ item.luckyBallFreq }}</td>
                        </tr>
                        <tr class="numFreq_results_row"
                            v-if="game === 'classicLotto' || game === 'rollingCashFive' || game === 'pick3' || game === 'pick4' || game === 'pick5'"
                            v-for="(item, index) in individualNumbers" :key="index">
                            <td class="numFreq_number">{{ item.number }}</td>
                            <td class="numFreq_freq">{{ item.whiteBallFreq }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    components: {

    },
    props: {
        gameName: String,
        startNumber: Number,
        endNumber: Number

    },
    data: function () {
        return {
            showResults: false,
            game: this.gameName,
            startArrNumber: this.startNumber,
            endArrNumber: this.endNumber,
            searchNumber: 0,
            ballNumber: 0,
            individualNumbers: [],
            dateRangeValue: '',
            MegiplierStatus: this.getFeatureSwitch().megaplier,
        }
    },
    mounted: function () {
    },
    methods: {
        go() {

            this.getToken();
            // console.log(this.searchNumber);
            // if( this.dateRangeValue > 0 && this.searchNumber > 0){
            //     this.getToken();
            // }else{
            //     this.showResults = false;

            // }

        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

        },
        fetchData(token) {
            var apiToken = token;
            var urlProtocol = this.getProtocol();
            var gameNameApi = this.game;
            var sinceDate;
            var toDate = moment().format("MM-DD-YYYY");

            var subtractRangedays = this.dateRangeValue;

            if (subtractRangedays == "12345") {
                sinceDate = '05-17-2002'
            } else {
                sinceDate = moment(moment().subtract(subtractRangedays, 'days')).format("MM-DD-YYYY");
            }

            // var sinceDate = "3/31/2000";
            var number = this.searchNumber;
            // alert('Mega token: '+token);

            // var ApiCalllUrl = "";

            // if (gameNameApi === 'megaMillions' || gameNameApi === 'powerBall' || gameNameApi === 'luckyForLife') {
            //     ApiCalllUrl = "/1.0/Games/DrawGames/" + gameNameApi + "/GetFrequency?number=" + number + "&date=" + encodeURIComponent(sinceDate);
            // } else {
            //     ApiCalllUrl = "/1.0/Games/DrawGames/" + gameNameApi + "/SearchNumberFrequencyDateRange?number=" + number + "&sinceDate=" + encodeURIComponent(sinceDate) + "&toDate=" + encodeURIComponent(toDate);
            // }

            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/GetFrequency?number=" + number + "&date=" + encodeURIComponent(sinceDate), {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    //console.log(json)
                    this.showResults = true;
                    this.individualNumbers = results;
                    this.ballNumber = this.searchNumber;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        changeRange(event) {
            this.dateRangeValue = event.target.value;
        },
        changeNumber(event) {
            this.searchNumber = event.target.value
        }
    },
    computed: {
        numbersArray() {
            const array = [];
            const endNumber = this.endArrNumber;
            let currentNumber = this.startArrNumber;
            for (var i = endNumber; i <= currentNumber; i++) {
                array.push(i);
            }
            return array;
        }
    }

};

</script>

<style scoped>
select {
    line-height: inherit;
    width: 75%
}

.number_frequency_results {

    th,
    td {
        text-align: center;
    }
}</style>