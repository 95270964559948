<template>
    <div class="drawNumbers-container">
        <span v-if="!evening" class="winNumDate">{{ new Date(numbersData.DrawDate) | moment("MM/DD/YYYY") }}</span>
        <!-- <span v-if="overGame.identifier === 'pick3' || overGame.identifier === 'pick4' || overGame.identifier === 'pick5'">
            <span class="pickGame" v-if="!evening">MIDDAY</span>
            <span class="pickGame" v-if="evening">EVENING</span>
        </span> -->
        <span v-if="overGame.StringIdentifier === 'Pick3' || overGame.StringIdentifier === 'Pick4' || overGame.StringIdentifier === 'Pick5' || overGame.identifier === 'pick3' || overGame.identifier === 'pick4' || overGame.identifier === 'pick5'">
            <span class="pickGame" v-if="!evening">MIDDAY</span>
            <span class="pickGame" v-if="evening">EVENING</span>
        </span>
        <!-- <span class="winNumToD">{{ numbersData.modifier | pickToD }}</span> -->
        <!-- Basic Game -->
        <ul v-if="!mega & !power & !lucky" class="winningNumbersList">
            <li v-for="(number) in numbersData.Numbers" v-bind:key="number.Id" style="display: inline-block;">
                <span class="winNumBall">{{ number.Value }}</span>
            </li>
        </ul>
        <!-- Lucky for Life -->
        <ul v-if="lucky" class="winningNumbersList">
            <li v-for="(number, index) in numbersData.Numbers" v-bind:key="number.Id" style="display: inline-block;">
                <span v-if="index !== last & index !== last" class="winNumBall">{{ number.Value }}</span>
                <span v-if="index == last" class="winNumBall" :class="{ 'ball_yellow':mega, 'ball_red':power, 'ball_yellow':lucky}">{{ number.Value }}</span>
            </li>
        </ul>
        <!-- Mega Millions / Powerball -->
        <ul v-if="mega || power" class="winningNumbersList">
            <li v-for="(number, index) in numbersData.Numbers" v-bind:key="number.Id" :style="{display: MegiplierStatus==='false' && index===6 ? 'none' : 'inline-block'}">
                <span v-if="index !== last & index !== last - 1" class="winNumBall">{{ number.Value }}</span>
                <span v-if="index == last - 1" class="winNumBall" :class="{ 'ball_yellow':mega, 'ball_red':lucky, 'ball_red':power }">{{ number.Value }}</span>
                <span v-if="index == last" class="winNumBall specialBall">x {{ number.Value }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: ['numbers', 'game', 'evening', 'component'],
    components: {
    },
    data() {
        return {
            overGame: this.game,
            numbersData: this.numbers,
            length: this.numbers.length,
            mega: false,
            power: false,
            lucky: false,
            MegiplierStatus: this.getFeatureSwitch().megaplier,
        }
    },
    mounted: function() {
        this.setNumbersData();
    },
    methods: {
        setNumbersData() {
            this.numbersData = this.numbers;

            // if (this.component === 0) {
                if (this.game.StringIdentifier === 'megamil') {
                    this.mega = true;
                } else if (this.game.StringIdentifier === 'pball') {
                    this.power = true;
                } else if (this.game.StringIdentifier === 'luckylife') {
                    this.lucky = true;
                }
            // } else {
            //     if (this.game.identifier === 'mega') {
            //         this.mega = true;
            //     } else if (this.game.identifier === 'power') {
            //         this.power = true;
            //     } else if (this.game.identifier === 'lucky') {
            //         this.lucky = true;
            //     }
            // }
        }
    },
    computed: {
        last() {
            return this.numbersData.Numbers.length - 1;
        }
    },
    watch: {
        numbers() {
            this.setNumbersData();
        },
        evening() {
            this.night = this.evening;
        }
    }
}
</script>

<style lang="css">
  
</style>