<template>
    <div class="cf eAppModule eAppPrincipalAuth">
        <h3>Principal Authorization</h3>

        <div class="eAppForm principalAuthStep1 grid-x grid-margin-x grid-margin-y" v-if="pstep == 1 && !isLoading">
            <p>Information provided is your personal contact and address information.</p>

            <div class="formRow cell labelRow">
                <span class="req">*</span>
                <label>Name:</label>
            </div>
            <div class="formRow nameTitleRow cell medium-2">
                <select v-model="principal.principalSalutation" name="ddlPrefix" id="ddlPrefix" class="selectTitle">
                    <option value="0">Mr.</option>
                    <option value="1">Mrs.</option>
                    <option value="2">Ms.</option>
                    <option value="3">Mss.</option>
                    <option value="4">Dr.</option>
                </select><!--validSalutation-->
            </div>
            <div class="formRow nameTitleRow cell medium-5">
                <input name="txbxFirstName" v-model="principal.principalFirstName" type="text" id="txbxFirstName" placeholder="First Name" autocomplete="off">
            </div>
            <div class="formRow nameTitleRow cell medium-5">
                <input name="txbxLastName" v-model="principal.principalLastName" type="text" id="txbxLastName" placeholder="Last Name" autocomplete="off">
            </div>
            <div class="formRow cell labelRow">
                    
                <span class="error" id="salutationError" v-if="!validSalutation">Please select a salutation</span>
                <span class="error" v-if="!validFirstName" id="firstNameError">Please add a first name</span>
                <span class="error" v-if="!validLastName" id="lastNameError">Please add a last name</span>
                </div>

            <div class="formRow cell">
                <label for="txbxTitle"><span class="req">*</span> Title:</label>
                <input name="txbxTitle" v-model="principal.principalTitle" type="text" id="txbxTitle" autocomplete="off">
                <span class="error" id="titleError" v-if="!validTitle">Please add a Title</span>
            </div>
            <div class="formRow cell">
                <span class="req">*</span> 
                <label for="txbxStreet" title="Please provide your home address.">Home Address:</label>
                <input name="txbxStreet" @input="uspsValidated = false" v-model="principal.principalStreetAddress" type="text" id="txbxStreet" autocomplete="off">
                <span class="error" id="streetError" v-if="!validStreet">Please add a Street Address</span>
            </div>
            <div class="formRow cell large-5">
                <label for="txbxCity"><span class="req">*</span> City:</label>
                <input name="txbxCity" @input="uspsValidated = false" v-model="principal.principalCity" type="text" id="txbxCity" autocomplete="off">
                <span class="error" id="cityError" v-if="!validCity">Please add a City</span>
            </div>
            
            <div class="formRow cell small-6 large-3">
                <span class="req">*</span>
                <label for="ddlStates" id="lblStates">State:</label>
                <select name="ddlStates" @change="uspsValidated = false" v-model="principal.principalState" id="ddlStates" class="form-control">
                    <option value="null">Select...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>

                </select>
                <span class="error" id="stateError" v-if="!validState">Please pick a State</span>
            </div>
            <div class="formRow zipFields cell zipFields small-6 large-4">
                <span class="req">*</span> 
                <label for="txbxZip" id="lblZip">Zip Code:</label>
                <input name="txbxZip" @input="uspsValidated = false" v-model="principal.principalZip" type="text" maxlength="5" id="txbxZip" autocomplete="off">
                <input name="txbxZip4" v-model="principal.principalZipPlus4" type="text" maxlength="4" id="txbxZip4" autocomplete="off">
                <span class="error" id="zipError" v-if="!validZip">Please add a Zip Code</span>
                <span class="error" id="zipFormatError" v-if="!validZipFormat">Please add a Zip Code format</span>
            </div>
            
            <div class="formRow cell">
                <span class="req">*</span> 
                <label for="txbxEmail">E-mail Address:</label>
                <input name="txbxEmail" v-model="principal.principalEmail" type="text" id="txbxEmail" autocomplete="off">
                <span class="error" id="emailError" v-if="!validEmail || !validEmailFormat">Please enter valid email address. Eg. Something@domain.com</span>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span> 
                <label for="txbxHomePhone">Home Phone:</label>
                <input name="txbxHomePhone" v-model="principal.principalHomePhoneNumber" type="text" id="txbxHomePhone" autocomplete="off" placeholder="555-555-5555" maxlength="12">
                <span class="error" id="homePhoneError" v-if="!validHomePhone">Please add a Home Phone</span>
                <span class="error" id="homePhoneFormatError" v-if="!validFormatHomePhone">Please add a valid Home Phone (ex: 555-555-5555)</span>
            </div>
            <div class="formRow cell medium-6">
                <label for="txbxCellPhone">Cell Phone:</label>
                <input name="txbxCellPhone" v-model="principal.principalCellPhoneNumber" type="text" id="txbxCellPhone" autocomplete="off" placeholder="555-555-5555" maxlength="12">
                <span class="error" id="cellPhoneFormatError" v-if="!validFormatCellPhone">Please add a valid Cell Phone (ex: 555-555-5555)</span>
            </div>
            <div class="formRow cell large-4">
                <span class="req">*</span> 
                <label for="txbxSSN">Social Security Number:</label>
                <input name="txbxSSN" v-model="principal.principalSSN" type="text" maxlength="9" id="txbxSSN" autocomplete="off">
                <span class="error" id="ssnError" v-if="!validSSN">Please enter a valid SSN</span>
                <span class="error" id="ssnErrorFormat" v-if="!validSSNFormat">Please enter SSN in a valid format</span>
            </div>
            <div class="formRow cell small-6 large-4">
                <span class="req">*</span> 
                <label for="txbxDOB">Date of Birth: </label>
                <input name="txbxDOB" v-model="principal.principalDateOfBirth" type="date" id="txbxDOB" class="" placeholder="mm/dd/yyyy">
                <span class="error" id="dobError" v-if="!validDob">Please add a date of birth</span>
                <span class="error" id="dobErrorFormat" v-if="!validDobFormat">Date of birth should be in proper format: mm/dd/yyyy</span>
            </div>
            <div class="formRow cell small-6 large-4">
                <span class="req">*</span> 
                <label for="txbxPercentOwnership">Percent Ownership:</label>
                <input name="txbxPercentOwnership" v-model="principal.principalPercentOwnership" type="text" id="txbxPercentOwnership" class="inputPercent" autocomplete="off" @input="restrictValue"> %
                <span class="error" id="percentError" v-if="!validPercent">Please add a percentage</span>
                <span class="error" id="percentValidError" v-if="!validPercentNumber">Value must be a whole number between 0 and 100</span>
                <span class="error" id="percentMaxError" v-if="!validPercentMax">The total should not exceed 100</span>
            </div>


            <div class="formRow cell formBtns">
                <!-- <input type="button" name="btnSave" @click="save()" value="Save" id="btnSaveStep1" class="button btnSave hidden"> -->
                <input type="button" name="BtnNext" @click="$refs.uspsValidate.validate()" value="Save &amp; Next" id="btnNextStep1" class="button btnNext">
            </div>
        </div>


        <div class="eAppForm principalAuthStep2 eapp_principalinformation" v-if="pstep == 2 && !isLoading">
            <div class="principalAuthRadios">

                <span class="req">*</span>
                <label>Has the applicant been convicted of ANY criminal offense, including ALL FELONIES AND ALL MISDEMEANORS, in ANY state and in ANY jurisdiction?</label>
                <input id="PrincipalAnyOffense_0" v-model="principal.principalAnyOffense" @change="principalAnyOffenseClick(0)" type="radio" name="PrincipalAnyOffense" value="false">
                <label for="PrincipalAnyOffense_0">No</label>
                <br>
                <input id="PrincipalAnyOffense_1" v-model="principal.principalAnyOffense" @change="principalAnyOffenseClick(1)" type="radio" name="PrincipalAnyOffense" value="true">
                <label for="PrincipalAnyOffense_1">Yes</label>
                <div class="error" v-if="!validAnyOffense"><br>Please select an answer.</div>

                <div id="PrincipalAnyOffense_Explanation" v-if="principal.principalAnyOffense">
                    <label for="PrincipalAnyOffense_Explanation">Please list the type of offense and the nature of ALL such convictions below (failure to report ANY and ALL convictions, will be considered a fraudulent misrepresentation, which will result in the immediate denial of this eApplication).</label>
                    <textarea rows="2" v-model="principal.principalAnyOffenseExplanation" cols="20" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validAnyOffenseExp">Please provide an answer.</span>
                </div>

            </div>
            <div class="principalAuthRadios">

                <span class="req">*</span>
                <label>Has the applicant been convicted of any offense involving gambling?</label>
                <input id="PrincipalGamblingOffense_0" v-model="principal.principalGamblingOffense" @change="principalGamblingOffenseClick(0)" type="radio" name="PrincipalGamblingOffense" value="false">
                <label for="PrincipalGamblingOffense_0">No</label>
                <br>
                <input id="PrincipalGamblingOffense_1" v-model="principal.principalGamblingOffense" @change="principalGamblingOffenseClick(1)" type="radio" name="PrincipalGamblingOffense" value="true">
                <label for="PrincipalGamblingOffense_1">Yes</label>
                <span class="error" v-if="!validGambling"><br>Please select an answer.</span>

                <div id="PrincipalGamblingOffense_Explanation" v-if="principal.principalGamblingOffense">
                    <label for="PrincipalGamblingOffense_Explanation">Please explain</label>
                    <textarea rows="2" v-model="principal.principalGamblingOffenseExplanation" cols="20" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validGamblingExp">Please provide an answer.</span>
                </div>

            </div>
            <div class="principalAuthRadios">
                <span class="req">*</span>
                <label>Has the applicant been found to violate any rule, regulation or order of the Ohio Lottery Commission?</label>
                <input id="PrincipalLotteryRuleViolation_0" v-model="principal.principalLotteryRuleViolation" @change="principalLotteryOffenseClick(0)" type="radio" name="PrincipalLotteryRuleViolation" value="false">
                <label for="PrincipalLotteryRuleViolation_0">No</label>
                <br>
                <input id="PrincipalLotteryRuleViolation_1" v-model="principal.principalLotteryRuleViolation" @change="principalLotteryOffenseClick(1)" type="radio" name="PrincipalLotteryRuleViolation" value="true">
                <label for="PrincipalLotteryRuleViolation_1">Yes</label>
                <span class="error" v-if="!validLotteryRule"><br>Please select an answer.</span>

                <div id="PrincipalLotteryRuleViolation_Explanation" v-if="principal.principalLotteryRuleViolation">
                    <label for="PrincipalLotteryRuleViolation_Explanation">Please explain</label>
                    <textarea rows="2" cols="20" v-model="principal.principalLotteryRuleViolationExplanation" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validLotteryRuleExp">Please provide an answer.</span>
                </div>

            </div>
            <div class="principalAuthRadios">
                <span class="req">*</span>
                <label>Has the applicant been found guilty or subjected to disciplinary action, by any agency, court or authority of any kind, for an offense involving fraud or misrepresentation?</label>
                <input id="PrincipalFraudOffense_0" v-model="principal.principalFraudOffense" @change="principalFraudOffenseClick(0)" type="radio" name="PrincipalFraudOffense" value="false">
                <label for="PrincipalFraudOffense_0">No</label>
                <br>
                <input id="PrincipalFraudOffense_1" v-model="principal.principalFraudOffense" @change="principalFraudOffenseClick(1)" type="radio" name="PrincipalFraudOffense" value="true">
                <label for="PrincipalFraudOffense_1">Yes</label>
                <span class="error" v-if="!validFraud"><br>Please select an answer.</span>

                <div id="PrincipalFraudOffense_Explanation" v-if="principal.principalFraudOffense">
                    <label for="PrincipalFraudOffense_Explanation">Please explain</label>
                    <textarea rows="2" v-model="principal.principalFraudOffenseExplanation" cols="20" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validFraudExp">Please provide an answer.</span>
                </div>

            </div>
            <div class="formRow cell formBtns">
                <input type="submit" name="btnSave" @click="save()" value="Save &amp; Back" id="btnSaveStep2" class="button btnSave hidden">
                <input type="submit" name="BtnNext" @click="saveNext()" value="Save &amp; Next" id="btnNextStep2" class="button btnNext">
            </div>
        </div>


        <div class="eAppForm principalAuthStep3" v-if="pstep == 3 && !isLoading">
            <div class="principalAuthRadios">
                <span class="req">*</span>
                <label>Has the applicant had any state license or permit revoked or suspended?</label>
                <span class="error" v-if="!validLicensePermit">Please select an answer.</span>
                <input id="PrincipalLicensePermit_0" v-model="principal.principalLicensePermit" @change="principalLicensePermitClick(0)" type="radio" name="PrincipalLicensePermit" value="false">
                <label for="PrincipalLicensePermit_0">No</label>
                <br>
                <input id="PrincipalLicensePermit_1" v-model="principal.principalLicensePermit" @change="principalLicensePermitClick(1)" type="radio" name="PrincipalLicensePermit" value="true">
                <label for="PrincipalLicensePermit_1">Yes</label>

                <div id="PrincipalLicensePermit_Explanation" v-if="principal.principalLicensePermit">
                    <label for="PrincipalLicensePermit_Explanation">Please explain</label>
                    <textarea rows="2" cols="20" id="PrincipalLicensePermit_Explanation" v-model="principal.principalLicensePermitExplanation" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validLicensePermitExp">Please provide an answer.</span>
                </div>

            </div>
            <div class="principalAuthRadios">
                <span class="req">*</span>
                <label>Has the applicant ever had any financial interest in a location that had an Ohio Lottery license?</label>
                <span class="error" v-if="!validPastFinancial">Please select an answer.</span>
                <input id="PrincipalPastFinancialInterest_0" v-model="principal.principalPastFinancialInterest" @change="principalPastFinancialInterestClick(0)" type="radio" name="PrincipalPastFinancialInterest" value="false">
                <label for="PrincipalPastFinancialInterest_0">No</label>
                <br>
                <input id="PrincipalPastFinancialInterest_1" v-model="principal.principalPastFinancialInterest" @change="principalPastFinancialInterestClick(1)" type="radio" name="PrincipalPastFinancialInterest" value="true">
                <label for="PrincipalPastFinancialInterest_1">Yes</label>

                <div id="PrincipalPastFinancialInterest_Explanation" v-if="principal.principalPastFinancialInterest">
                    <label for="PrincipalPastFinancialInterest_Explanation">Please explain</label>
                    <textarea rows="2" cols="20" v-model="principal.principalPastFinancialInterestExplanation" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validPastFinancialExp">Please provide an answer.</span>
                </div>
            </div>
            <div class="principalAuthRadios">
                <span class="req">*</span>
                <label>Does the applicant currently have any financial interest in a location that has an Ohio Lottery license?</label>
                <span class="error" v-if="!validCurrentFinancial">Please select an answer.</span>
                <input id="PrincipalCurrentFinancialInterest_0" v-model="principal.principalCurrentFinancialInterest" @change="principalCurrentFinancialInterestClick(0)" type="radio" name="PrincipalCurrentFinancialInterest" value="false">
                <label for="PrincipalCurrentFinancialInterest_0">No</label>
                <br>
                <input id="PrincipalCurrentFinancialInterest_1" v-model="principal.principalCurrentFinancialInterest" @change="principalCurrentFinancialInterestClick(1)" type="radio" name="PrincipalCurrentFinancialInterest" value="true">
                <label for="PrincipalCurrentFinancialInterest_1">Yes</label>

                <div id="PrincipalCurrentFinancialInterest_Explanation" v-if="principal.principalCurrentFinancialInterest">
                    <label for="PrincipalCurrentFinancialInterest_Explanation">Please explain</label>
                    <textarea rows="2" cols="20" v-model="principal.principalCurrentFinancialInterestExplanation" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validCurrentFinancialExp">Please provide an answer.</span>
                </div>
            </div>
            <div class="principalAuthRadios">
                <span class="req">*</span>
                <label>Does any other person or entity other than the applicant, its employees, or family participate in the management of applicant's affairs? </label>
                <span class="error" v-if="!validAffairs">Please select an answer.</span>
                <input id="PrincipalAffairs_0" v-model="principal.principalAffairs" @change="principalAffairsClick(0)" type="radio" name="PrincipalAffairs" value="false">
                <label for="PrincipalAffairs_0">No</label>
                <br>
                <input id="PrincipalAffairs_1" v-model="principal.principalAffairs" @change="principalAffairsClick(1)" type="radio" name="PrincipalAffairs" value="true">
                <label for="PrincipalAffairs_1">Yes</label>

                <div id="PrincipalAffairs_Explanation" v-if="principal.principalAffairs">
                    <label for="PrincipalAffairs_Explanation">Please explain</label>
                    <textarea rows="2" cols="20" v-model="principal.principalAffairsExplanation" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validAffairsExp">Please provide an answer.</span>
                </div>
            </div>
            <div class="principalAuthRadios formRow cell dividerRow">
                <span class="req">*</span>
                <label>Are you a vendor, employee or agent of any vendor of the Ohio Lottery Commission?</label>
                <span class="error" v-if="!validVender">Please select an answer.</span>
                <input id="PrincipalIsVendor_0" v-model="principal.principalIsVendor" @change="principalIsVendorClick(0)" type="radio" name="PrincipalIsVendor" value="false">
                <label for="PrincipalIsVendor_0">No</label>
                <br>
                <input id="PrincipalIsVendor_1" v-model="principal.principalIsVendor" @change="principalIsVendorClick(1)" type="radio" name="PrincipalIsVendor" value="true">
                <label for="PrincipalIsVendor_1">Yes</label>

                <div id="PrincipalIsVendor_Explanation" v-if="principal.principalIsVendor">
                    <label for="PrincipalIsVendor_Explanation">Please explain</label>
                    <textarea rows="2" cols="20" v-model="principal.principalIsVendorExplanation" class="radioExplanation"></textarea>
                    <span class="error" v-if="!validVendorExp">Please provide an answer.</span>
                </div>
            </div>
            <div class="formRow cell checkboxRow">
                <input id="PrincipalTerms_0" v-model="terms" @click="principalTermsClick()" type="checkbox" name="PrincipalTerms">
                <span class="req">*</span>
                <label for="PrincipalTerms_0">I acknowledge my digital signature will be added to this document at time of submission</label>
                <span class="error" v-if="!validTerms">Please acknowledge your digital signature.</span>
            </div>
            <div class="formRow cell checkboxRow">

                <input id="PrincipalAuthorizedBackgroundCheck_0" v-model="auth" @click="principalAuthorizeClick()" type="checkbox" name="PrincipalAuthorizedBackgroundCheck">
                <span class="req">*</span>
                <label for="PrincipalAuthorizedBackgroundCheck_0">I authorize the Ohio Lottery Commission to conduct a background check using the information provided above.</label>
                <span class="error" v-if="!validAuth">Please authorize the Ohio Lottery Commission.</span>

            </div>
            <div class="formRow cell checkboxRow">
                <input id="PrincipalIAcknowledgeUnderstandQuestions_0" v-model="ack" type="checkbox" @click="principalAckClick()" name="PrincipalIAcknowledgeUnderstandQuestions">
                <span class="req">*</span>
                <label for="PrincipalIAcknowledgeUnderstandQuestions_0">I acknowledge that I have read and understand all of the foregoing questions, that I have answered them completely and truthfully, and that failure to do so will result in the immediate denial of this eApplication.</label>
                <span class="error" v-if="!validAck">Please acknowledge.</span>
            </div>
            <div class="btn-actions formBtns">
                <button type="button" id="btnSaveStep3" @click="save()" class=" button btnSave hidden" title="Save the document">Save &amp; Back</button>
                <button type="button" id="btnNextStep3" @click="saveNext()" class="button btnNext" title="Save the document and proceed to next page">Save &amp; Next</button>
            </div>
        </div>
        <USPSAddressCheckVue :mailingAddress="principal.principalStreetAddress" :city="principal.principalCity" :state="principal.principalState" :zipcode="principal.principalZip" :zipFour="principal.principalZipPlus4" :allowMultiple="true" ref="uspsValidate"></USPSAddressCheckVue>
    </div>
</template>


<script>
    import moment from 'moment';
    import $ from 'jquery';
    import USPSAddressCheckVue from '../../Shared/USPS-AddressCheck.vue';

    export default {
        props: {
            principalJson: String
        },
        components: {
            USPSAddressCheckVue
        },
        data: function () {
            return {
                pstep: 1,
                principal: {
                    principalSalutation: 0
                },
                isLoading: true,
                terms: false,
                auth: false,
                ack: false,
                valid: false,
                validTitle: true,
                validFirstName: true,
                validLastName: true,
                validSalutation: true,
                validStreet: true,
                validState: true,
                validCity: true,
                validZip: true,
                validZipFormat: true,
                validHomePhone: true,
                validFormatHomePhone: true,
                validCellPhone: true,
                validFormatCellPhone: true,
                validDob: true,
                validDobFormat: true,
                validPercent: true,
                validEmail: true,
                validSSNFormat: true,
                validSSN: true,
                validPercentNumber: true,
                validPercentMax: true,
                validEmailFormat: true,
                validAnyOffense: true,
                validAnyOffenseExp: true,
                validGambling: true,
                validGamblingExp: true,
                validLotteryRule: true,
                validLotteryRuleExp: true,
                validFraud: true,
                validFraudExp: true,
                validLicensePermit: true, 
                validLicensePermitExp: true,
                validPastFinancial: true,
                validPastFinancialExp: true,
                validCurrentFinancial: true,
                validCurrentFinancialExp: true,
                validAffairs: true, 
                validAffairsExp: true,
                validVender: true, 
                validVendorExp: true,
                validTerms: true,
                validAck: true,
                validAuth: true,
                pastValue: '',
                principalAnyOffense: null,
                principalGamblingOffense: null,
                principalLotteryRuleViolation: null,
                principalFraudOffense: null,
                principalLicensePermit: null,
                principalPastFinancialInterest: null,
                principalCurrentFinancialInterest: null,
                principalAffairs: null,
                principalIsVendor: null
            }
        },
        mounted: function () {

            this.isLoading = true;
            this.onMounted();

            $(".eAppPrincipalAuth").foundation();
           
        },
        methods: {
            restrictValue() {
                let value = this.principal.principalPercentOwnership,
                    rule = /^[0-9]*$/,
                    result = rule.test(value);

                if (result === true) {
                    if (value > 100) {
                        // this.pastValue = 100;
                    } else if (value < 0) {
                        this.pastValue = 0;
                    } else {
                        this.pastValue = value;
                    }
                }

                this.principal.principalPercentOwnership = this.pastValue;
            },
            onMounted() {
                this.tryGetFromQueryString().then(() => {
                    this.deserializeJson();
                    this.isLoading = false;
                    setTimeout(() => {
                        this.loading = false
                        this.$nextTick(() => this.addFormatters() );
                    }, 1000);
                    
                });
            },
            addFormatters() {
                if (this.pstep == 1) {
                    // this.phoneNumberFormat(document.querySelector('#txbxHomePhone'));
                    // this.phoneNumberFormat(document.querySelector('#txbxCellPhone'));
                    this.formatDate(document.querySelector('#txbxDOB'));
                }
            },
            deserializeJson() {
                this.principal = JSON.parse(this.principalJson);
                if (this.principal.principalDateOfBirth) {
                    
                    // this.principal.principalDateOfBirth = moment(this.principal.principalDateOfBirth).format('L');

                    let input = $('#txtbxDOB');

                    let days = moment(this.principal.principalDateOfBirth).format('YYYY-MM-DD');
                    this.principal.principalDateOfBirth = days;
                    this.terms = this.principal.principalTerms;
                    this.auth = this.principal.principalAuthorizedBackgroundCheck;
                    this.ack = this.principal.principalIAcknowledgeUnderstandQuestions;

                    if(this.principal.principalSalutation === null || this.principal.principalSalutation === undefined || this.principal.principalSalutation === 'null') {
                        this.principal.principalSalutation = "-1";
                    }

                    if(this.principal.principalAnyOffense !== null){
                        this.principalAnyOffense = this.principal.principalAnyOffense;
                    }

                    if(this.principal.principalGamblingOffense !== null){
                        this.principalGamblingOffense = this.principal.principalGamblingOffense;
                    }

                    if(this.principal.principalLotteryRuleViolation !== null){
                        this.principalLotteryRuleViolation = this.principal.principalLotteryRuleViolation;
                    }

                    if(this.principal.principalFraudOffense !== null){
                        this.principalFraudOffense = this.principal.principalFraudOffense;
                    }

                    if(this.principal.principalLicensePermit !== null){
                        this.principalLicensePermit = this.principal.principalLicensePermit;
                    }

                    if(this.principal.principalPastFinancialInterest !== null){
                        this.principalPastFinancialInterest = this.principal.principalPastFinancialInterest;
                    }

                    if(this.principal.principalCurrentFinancialInterest !== null){
                        this.principalCurrentFinancialInterest = this.principal.principalCurrentFinancialInterest;
                    }

                    if(this.principal.principalAffairs !== null){
                        this.principalAffairs = this.principal.principalAffairs;
                    }

                    if(this.principal.principalIsVendor !== null){
                        this.principalIsVendor = this.principal.principalIsVendor;
                    }
                    
                }
            },
            tryGetFromQueryString() {
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('n')) {
                    this.pstep = urlParams.get('n');
                }
                return Promise.resolve();
            },
            principalAnyOffenseClick(option) {
                if (option == "0") {
                    
                    this.principalAnyOffense = false;
                    this.principal.principalAnyOffense = false;
                    this.principal.principalAnyOffenseExplanation = '';
                }else{
                    this.principalAnyOffense = true;
                    this.principal.principalAnyOffense = true;
                    this.principal.principalAnyOffenseExplanation = this.principal.principalAnyOffenseExplanation;
                }
            },
            principalFraudOffenseClick(option) {
                if (option == "0") {
                    this.principalFraudOffense = false;
                    this.principal.principalFraudOffense = false;
                    this.principal.principalFraudOffenseExplanation = '';
                }else{
                    this.principalFraudOffense = true;
                    this.principal.principalFraudOffense = true;
                    this.principal.principalFraudOffenseExplanation = this.principal.principalFraudOffenseExplanation;    
                }
            },
            principalGamblingOffenseClick(option) {
                if (option == "0") {
                    this.principalGamblingOffense = false;
                    this.principal.principalGamblingOffense = false;
                    this.principal.principalGamblingOffenseExplanation = '';
                }else{
                    this.principalGamblingOffense = true;
                    this.principal.principalGamblingOffense = true;
                    this.principal.principalGamblingOffense = this.principal.principalGamblingOffense;
                }
            },
            principalLotteryOffenseClick(option) {
                if (option == "0") {
                    this.principalLotteryRuleViolation = false;
                    this.principal.principalLotteryRuleViolation = false;
                    this.principal.principalLotteryRuleViolationExplanation = '';
                }else{
                    this.principalLotteryRuleViolation = true;
                    this.principal.principalLotteryRuleViolation = true;
                    this.principal.principalLotteryRuleViolationExplanation = this.principal.principalLotteryRuleViolationExplanation;
                }
            },
            principalLicensePermitClick(option) {
                if (option == "0") {
                    this.principalLicensePermit = false;
                    this.principal.principalLicensePermit = false;
                    this.principal.principalLicensePermitExplanation = '';
                }else{
                    this.principalLicensePermit = true;
                    this.principal.principalLicensePermit = true;
                    this.principal.principalLicensePermitExplanation = this.principal.principalLicensePermitExplanation;
                }
            },
            principalPastFinancialInterestClick(option) {
                if (option == 0) {
                    this.principalPastFinancialInterest = false;
                    this.principal.principalPastFinancialInterest = false;
                    this.principal.principalPastFinancialInterestExplanation = '';
                }else{
                    this.principalPastFinancialInterest = true;
                    this.principal.principalPastFinancialInterest = true;
                    this.principal.principalPastFinancialInterestExplanation = this.principal.principalPastFinancialInterestExplanation;
                }
            },
            principalCurrentFinancialInterestClick(option) {
                if (option == 0) {
                    this.principalCurrentFinancialInterest = false;
                    this.principal.principalCurrentFinancialInterest = false;
                    this.principal.principalCurrentFinancialInterest = '';
                }else{
                    this.principalCurrentFinancialInterest = true;
                    this.principal.principalCurrentFinancialInterest = true;
                    this.principal.principalCurrentFinancialInterest = this.principal.principalCurrentFinancialInterest;
                }
            },
            principalAffairsClick(option) {
                if (option == 0) {
                    this.principalAffairs = false;
                    this.principal.principalAffairs = false;
                    this.principal.principalAffairsExplanation = '';
                }else{
                    this.principalAffairs = true;
                    this.principal.principalAffairs = true;
                    this.principal.principalAffairsExplanation = this.principal.principalAffairsExplanation;
                }
            },
            principalIsVendorClick(option) {
                if (option == 0) {
                    this.principalIsVendor = false;
                    this.principal.principalIsVendor = false;
                    this.principal.principalIsVendorExplanation = '';
                }else{
                    this.principalIsVendor = true;
                    this.principal.principalIsVendor = true;
                    this.principal.principalIsVendorExplanation = this.principal.principalIsVendorExplanation;
                }
            },
            principalTermsClick() {
                this.terms = !this.terms;
                if (this.terms) {
                    this.principal.principalTerms = true;
                }
                else {
                    this.principal.principalTerms = false;
                }
            },
            principalAuthorizeClick() {
                this.auth = !this.auth;
                if (this.auth) {
                    this.principal.principalAuthorizedBackgroundCheck = true;
                }
                else {
                    this.principal.principalAuthorizedBackgroundCheck = false;
                }
                
            },
            principalAckClick() {
                this.ack = !this.ack;
                if (this.ack) {
                    this.principal.principalIAcknowledgeUnderstandQuestions = true;
                }
                else {
                    this.principal.principalIAcknowledgeUnderstandQuestions = false;
                }
            },
            save() {
                this.onSaveClick('back');
            },
            saveNext() {
                this.onSaveClick('next');
            },
            onSaveClick(dir) {
                console.log('Validating...')
                if (this.validate()) {
                    this.$emit('onPrincipalSaveClick', this.principal, dir);
                    return true;
                }
                return false;
            },
            validate() {
                let valid = false;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('n')) {
                    this.pstep = urlParams.get('n');

                    console.log(this.pstep)

                    switch (this.pstep) {
                        case "1":
                            if (!this.principal.principalTitle) {
                                this.validTitle = false;
                            }
                            else {
                                this.validTitle = true;
                            }
                            
                            if (this.principal.principalSalutation < 0) {
                                this.validSalutation = false;
                            }
                            else {
                                this.validSalutation = true;
                            }

                            if (!this.principal.principalFirstName) {
                                this.validFirstName = false;
                            }
                            else {
                                this.validFirstName = true;
                            }

                            if (!this.principal.principalLastName) {
                                this.validLastName = false;
                            }
                            else {
                                this.validLastName = true;
                            }

                            if (!this.principal.principalStreetAddress) {
                                this.validStreet = false;
                            }
                            else {
                                this.validStreet = true;
                            }

                            if (!this.principal.principalZip || !this.principal.principalZipPlus4) {
                                this.validZip = false;
                            }
                            else {
                                this.validZip = true;
                            }

                            if (!this.zipValidation(this.principal.principalZip) || !this.zipPlusFourValidation(this.principal.principalZipPlus4)) {
                                this.validZipFormat = false;
                            }
                            else {
                                this.validZipFormat = true;
                            }
                            // zipValidation
                            // zipPlusFourValidation

                            if (!this.principal.principalState) {
                                this.validState = false;
                            }
                            else {
                                this.validState = true;
                            }

                            if (!this.principal.principalCity) {
                                this.validCity = false;
                            }
                            else {
                                this.validCity = true;
                            }

                            if (!this.principal.principalHomePhoneNumber) {
                                this.validHomePhone = false;
                            }
                            else {
                                this.validHomePhone = true;
                            }

                            if (!this.phoneValidation(this.principal.principalHomePhoneNumber)) {
                                this.validFormatHomePhone = false;
                            }
                            else {
                                this.validFormatHomePhone = true;
                            }

                            if (this.principal.principalCellPhoneNumber) {
                            //     this.validCellPhone = false;
                            // }
                            // else {
                            //     this.validCellPhone = true;

                                if (!this.phoneValidation(this.principal.principalCellPhoneNumber)) {
                                    this.validFormatCellPhone = false;
                                }
                                else {
                                    this.validFormatCellPhone = true;
                                }
                            }

                            if (!this.principal.principalDateOfBirth) {
                                this.validDob = false;
                            }
                            else {
                                this.validDob = true;
                            }
                            // console.log(this.principal.principalDateOfBirth,moment(this.principal.principalDateOfBirth).format('L'),this.dateValidation(moment(this.principal.principalDateOfBirth).format('L')));

                            
                            if (!this.dateValidation(moment(this.principal.principalDateOfBirth).format('L'))) {
                                this.validDobFormat = false;
                            }
                            else {
                                this.validDobFormat = true;
                            }

                            if (!this.ssnValidation(this.principal.principalSSN)) {
                                this.validSSNFormat = false;
                            }
                            else {
                                this.validSSNFormat = true;
                            }

                            if (!this.principal.principalSSN) {
                                this.validSSN = false;
                            }
                            else {
                                this.validSSN = true;
                            }

                            if (this.principal.principalPercentOwnership === null || this.principal.principalPercentOwnership === undefined) {
                                this.validPercent = false;
                            }
                            else {
                                this.validPercent = true;
                            }

                            if (isNaN(this.principal.principalPercentOwnership)) {
                                this.validPercentNumber = false;
                            }
                            else {
                                this.validPercentNumber = true;
                            }

                            if (this.principal.principalPercentOwnership < 0 || this.principal.principalPercentOwnership > 100) {
                                this.validPercentMax = false;
                            }
                            else {
                                this.validPercentMax = true;
                            }

                            if (!this.emailValidation(this.principal.principalEmail)) {
                                this.validEmailFormat = false;
                            }
                            else {
                                this.validEmailFormat = true;
                            }

                            if (!this.principal.principalEmail) {
                                this.validEmail = false;
                            }
                            else {
                                this.validEmail  = true;
                            }

                            if (this.validTitle
                                && this.validSalutation
                                && this.validFirstName
                                && this.validFirstName
                                && this.validStreet
                                && this.validZip
                                && this.validState
                                && this.validCity
                                && this.validHomePhone
                                && this.validFormatHomePhone
                                && this.validCellPhone
                                && this.validFormatCellPhone
                                && this.validDob
                                && this.validSSN
                                && this.validSSNFormat
                                && this.validEmail
                                && this.validEmailFormat
                                && this.validPercentNumber
                                && this.validPercentMax
                                && this.validPercent) {
                                valid = true;
                            }
                            else {
                                valid = false;
                            }
                            break;

                        case "2":
                            // console.log(this.principal.principalAnyOffense.toString());
                            // this.validAnyOffense = JSON.parse(this.principal.principalAnyOffense.toString().toLowerCase());
                            // if (this.principal.principalAnyOffense === "false") {
                            //     this.validAnyOffense
                            // } else if (this.principal.principalAnyOffense === "true") {
                            //     this.validAnyOffense
                            // } else {

                            // }
                            this.principal.principalAnyOffense = this.principalAnyOffense;
                            this.principal.principalGamblingOffense = this.principalGamblingOffense;
                            this.principal.principalLotteryRuleViolation = this.principalLotteryRuleViolation;
                            this.principal.principalFraudOffense = this.principalFraudOffense;

                            this.validAnyOffense = this.principal.principalAnyOffense !== null;

                            this.validAnyOffenseExp = (this.principal.principalAnyOffense == 'true') ? this.principal.principalAnyOffenseExplanation.trim() != '' : true;
                            
                            // this.validGambling = JSON.parse(this.principal.principalGamblingOffense.toString().toLowerCase());
                            this.validGambling = this.principal.principalGamblingOffense !== null;
                            this.validGamblingExp = (this.principal.principalGamblingOffense == 'true') ? this.principal.principalGamblingOffenseExplanation.trim() != '' : true;
                            
                            // this.validLotteryRule = JSON.parse(this.principal.principalLotteryRuleViolation.toString().toLowerCase());
                            this.validLotteryRule = this.principal.principalLotteryRuleViolation !== null;
                            this.validLotteryRuleExp = (this.principal.principalLotteryRuleViolation == 'true') ? this.principal.principalLotteryRuleViolationExplanation.trim() != '' : true;

                            // this.validFraud = JSON.parse(this.principal.principalFraudOffense.toString().toLowerCase());
                            this.validFraud = this.principal.principalFraudOffense !== null;
                            this.validFraudExp = (this.principal.principalFraudOffense == 'true') ? this.principal.principalFraudOffenseExplanation.trim() != '' : true;

                            // console.log(this.validAnyOffense)
                            // console.log(this.validAnyOffenseExp)
                            // console.log(this.validGambling)
                            // console.log(this.validGamblingExp)
                            // console.log(this.validLotteryRule)
                            // console.log(this.validLotteryRuleExp)
                            // console.log(this.validFraud)
                            // console.log(this.validFraudExp)

                            if (this.validAnyOffense
                                && this.validAnyOffenseExp
                                && this.validGambling
                                && this.validGamblingExp
                                && this.validLotteryRule
                                && this.validLotteryRuleExp
                                && this.validFraud
                                && this.validFraudExp
                            ) {
                                valid = true;
                            }
                            else {
                                valid = false;
                            } 
                            break;

                        case "3":
                            this.principal.principalLicensePermit = this.principalLicensePermit;
                            this.principal.principalPastFinancialInterest = this.principalPastFinancialInterest;
                            this.principal.principalCurrentFinancialInterest = this.principalCurrentFinancialInterest;
                            this.principal.principalAffairs = this.principalAffairs;
                            this.principal.principalIsVendor = this.principalIsVendor;
                            // this.validLicensePermit = JSON.parse(this.principal.principalLicensePermit.toString().toLowerCase())
                            this.validLicensePermit = this.principal.principalLicensePermit !== null;
                            this.validLicensePermitExp = (this.principal.principalLicensePermit.toString() == 'true') ? this.principal.principalLicensePermitExplanation.trim() != '' : true;

                            // this.validPastFinancial = JSON.parse(this.principal.principalPastFinancialInterest.toString().toLowerCase())
                            this.validPastFinancial = this.principal.principalPastFinancialInterest !== null;
                            this.validPastFinancialExp = (this.principal.principalPastFinancialInterest.toString() == 'true') ? this.principal.principalPastFinancialInterestExplanation.trim() != '' : true;

                            // this.validCurrentFinancial = JSON.parse(this.principal.principalCurrentFinancialInterest.toLowerCase())
                            this.validCurrentFinancial = this.principal.principalCurrentFinancialInterest !== null;
                            this.validCurrentFinancialExp = (this.principal.principalCurrentFinancialInterest.toString() == 'true') ? this.principal.principalCurrentFinancialInterestExplanation.trim() != '' : true;

                            // this.validAffairs = JSON.parse(this.principal.principalAffairs.toString().toLowerCase())
                            this.validAffairs = this.principal.principalAffairs !== null;
                            this.validAffairsExp = (this.principal.principalAffairs.toString() == 'true') ? this.principal.principalAffairsExplanation.trim() != '' : true;

                            // this.validVender = JSON.parse(this.principal.principalIsVendor.toString().toLowerCase())
                            this.validVender = this.principal.principalIsVendor !== null;
                            this.validVendorExp = (this.principal.principalIsVendor.toString() == 'true') ? this.principal.principalIsVendorExplanation.trim() != '' : true;
                            
                            this.validTerms = this.terms;
                            this.validAuth = this.auth;
                            this.validAck = this.ack;
                            // this.validTerms = this.principal.principalTerms == 1;
                            // this.validAuth = this.principal.principalAuthorizedBackgroundCheck == 1;
                            // this.validAck = this.principal.principalIAcknowledgeUnderstandQuestions == 1;

                            if (this.validLicensePermit
                                && this.validLicensePermitExp
                                && this.validPastFinancial
                                && this.validPastFinancialExp
                                && this.validCurrentFinancial
                                && this.validCurrentFinancialExp
                                && this.validAffairs
                                && this.validAffairsExp
                                && this.validVender
                                && this.validVendorExp
                                && this.validTerms
                                && this.validAuth
                                && this.validAck
                            ) {
                                valid = true;
                            }
                            else {
                                valid = false;
                            }

                            break;
                        default:
                            break;
                    }
                }
                
                return valid;
            },
            // uspsValidation(msg) {
                // console.log("Validationg Happening")
                // if (this.uspsValidated === true) {
                //     this.saveNext(this.pstep);
                // } else {
                //     this.$refs.uspsValidate.validate(msg);
                // }
            // },
            uspsOpen() {
                $('.eAppPrincipalAuth').foundation();
                $('#USPSmodal').foundation('open');
            },
            uspsClose() {
                $('#USPSmodal').foundation('close');
            },
            uspsVerified(msg) {
                this.principal.principalStreetAddress = msg.mailingAddress;
                this.principal.principalCity = msg.city;
                this.principal.principalState = msg.state;
                this.principal.principalZip = msg.zip;
                this.principal.principalZipPlus4 = msg.zipFour;

                this.uspsClose();

                this.saveNext(this.pstep)
            },
            // navigateToNextStep(num) {
            //     let currentPage = window.location.origin + window.location.pathname;
            //     let queryString = window.location.search;
            //     let urlParams = new URLSearchParams(queryString);

            //     num++;
            //     if (num > 3) {
            //         urlParams.delete('n');
            //         if (urlParams.has('step')) {
            //             urlParams.set('step', 'background');
            //         } else {
            //             urlParams.append('step', 'background');
            //         }
            //     }
            //     else {
            //         if (urlParams.has('n')) {
            //             urlParams.set('n', num);
            //         } else {
            //             urlParams.append('n', num);
            //         }
            //     }
                

            //     currentPage += '?' + urlParams.toString();
            //     window.location.href = currentPage;
            // },
            formatPhone() {
                $(document).foundation();
                $('#txbxHomePhone').value = this.phoneNumberFormat(document.getElementById('txbxHomePhone').value);
                if (document.getElementById('txbxCellPhone').value !== '') {
                    $('#txbxCellPhone').value = this.phoneNumberFormat(document.getElementById('txbxCellPhone').value);
                }
            },
            formatDate(date) {
                return moment(date).format('L');
            }
        },
        computed: {
            customFormatter(date) {
                return moment(date).format('L');
            }
        }

        
    };
</script>

<style lang="scss" scoped>

</style>
