<template>
    <div class="mlrHomeHeader">
        <div class="mlrLogoHeader23" v-if="isUserLogged">
            <div class="mlrHeaderLogo">
                <div alt="MLR Logo" class="img-responsive" id="mlrLogo" />
            </div>
            <div class="mlrHeaderUserInfo">
                <h2>Hello <span v-if="nickname.length">{{ nickname }}</span><span v-if="nickname === ''">{{ username }}</span>, </h2>
                <p>You have <span class="currentBalance">{{pointBalance.toLocaleString()}} pts.</span></p>
                <a href="/MyLotto-Rewards/Redemption-Central" class="button btn_purple btn_dkBlue">Redeem Points</a>
                <div class="mlrHeaderLogoLinks">
                    <a href="/MyLotto-Rewards/My-Account" class="btn_pointHistory btn_whiteLink">My Points History</a>
                    <a href="/MyLotto-Rewards/My-Account/My-Profile" class="btn_myProfile btn_whiteLink">My Profile</a>
                </div>
            </div>
        </div>

        <div class="mlrIntro cf" v-if="!isUserLogged">
            <h1>Play&nbsp;Games. Earn&nbsp;Points. Get&nbsp;Rewarded.</h1>
            <p class="intro">Sign-in or register to enter your promotion codes or shop our catalog.</p>
            <a class="button btn_popupLogIn" href="#" id="globalSignIn">Sign In</a>
            <a class="button btn_joinNow" href="/myLotto/My-Account/Register" id="globalRegister">Register</a>
        </div>
        <Achievements/>
        <div class="modulePrpl quickTicketEntry cf" v-if="isUserLogged">
            <div :class="contentLeftClasses" v-html="contentLeft"></div>
            <!-- <div :class="contentRightClasses" v-html="contentRight"></div> -->
        </div>

        <PromotionCodeEntry v-if="isUserLogged" contentKey="promotion-code-entry-text" requireDigits="3"></PromotionCodeEntry>
    </div>
</template>

<script>
    import PromotionCodeEntry from '../TicketEntry/PromotionCodeEntry.vue';
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import Achievements from './Achievements/Achievements.vue';

    export default {
        components:{
            PromotionCodeEntry,
            SignInRegister,
            Achievements
        },
        data: function () {
            return {
                username: '',
                nickname: '',
                token: '',
                pointBalance: '',
                entered: '',
                footerlinks: {
                    link1text: '',
                    link1url: '',
                    link2text: '',
                    link2url: ''
                },
                heading: '',
                logo: '',
                nonauthheadline: '',
                nonauthsubhaedline: '',
                placeholder: '',
                pointsbuttontext: '',
                pointsbuttonurl: '',
                profilebutton: '',
                profilebuttonurl: '',
                submit: '',
                isUserLogged: false,
                contentLeft: '',
                contentRight: '',
                contentLeftClasses: '',
                contentRightClasses: ''
            }
        },
        mounted: function () {
            
            let apiToken = this.$cookies.get('apiToken');
            // console.log(apiToken);
            if(apiToken.user.username == "mobilepublic@mtllc.com"){
                this.isUserLogged = false;
                this.getToken();
                
            }else{
                this.isUserLogged = true
                
                this.getToken();

                this.username = apiToken.user.firstName;
                this.nickname = apiToken.user.nickname;
                this.pointBalance = apiToken.user.pointBalance.balance;
            }

            let widget = document.getElementsByClassName("rte-widget");

            this.isUserLogged === true ? widget[0].style.display = "none" : false;

            this.loadComponent();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.token = token;
                })
                .then(() => {
                    this.fetchData(this.token);
                    this.loadComponent;
                })
            },
            loadComponent() {
                $(document).ready(function () {
                    $('.close-button').on('click', function () {
                        $("#globalLogInModal").hide();
                        $("#globalLoginBlocker").hide();
                    })

                    $(".btn_popupLogIn").on('click', function () {
                        $("#globalLogInModal").show();
                        $("#globalLoginBlocker").show();
                    });
                })
            },
            fetchData(token) {
                var apiToken = token;
                console.log('fetching content')

                return fetch(process.env.VUE_APP_APIPROTOCOL +process.env.VUE_APP_APIURL + "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=RewardsLandingGreetingNoEntry", {
                        headers: {
                            Authorization: "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let res = json.data;
                    let greetingContent = res[0].centralContent;
                    let parsedContent = JSON.parse(greetingContent);

                    this.pointsbuttontext = parsedContent.pointsbuttontext;
                    this.pointsbuttonurl = parsedContent.pointsbuttonurl;
                    this.profilebutton = parsedContent.profilebutton;
                    this.profilebuttonurl = parsedContent.profilebuttonurl;
                    
                    this.footerlinks.link1text = parsedContent.footerlinks.link1text;
                    this.footerlinks.link2text = parsedContent.footerlinks.link2text;
                    this.footerlinks.link1url  = parsedContent.footerlinks.link1url;
                    this.footerlinks.link2url  = parsedContent.footerlinks.link2url;

                    this.contentLeft = parsedContent.contentleft;
                    this.contentLeftClasses = parsedContent.contentleftclasses;
                    // this.contentRight = parsedContent.contentright;
                    // this.contentRightClasses = parsedContent.contentrightclasses;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
           
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    .modulePrpl {
         .quickEntryGreeting {
            .button {
                display: inline-flex;
                align-items: center;
            }
         }
    }
    .mlrHomeHeader .quickTicketEntry .quickEntryGreeting p {
        margin-bottom: 0px;
    }
    .input-group {
        width: 100%;
    }
</style>