import Vue from "vue";

import MegaWN from "./MegaMillionsDrawDetail.vue";

export function load(payload) {
  const selector = "mega-DrawDetail";
  let store = payload;

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return; // go no further
  }

  // Create a new Vue app with the imported component
  new Vue({
    store,
    render: createElement =>
      createElement(MegaWN, {
        props: {
          ...window[selector]
        }
      }),
  }).$mount(selector);
}
